@import 'styles/styles.scss';

.fieldActionWrapper {
    display: flex;
    flex-direction: column;

    .fieldWrapper {
        width: 100%;
    }

    .btnWrapper {
        .btn {
            height: 34px;
            margin-bottom: 5px;
        }

        margin-top: 10px;
    }
}

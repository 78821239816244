@import 'styles/styles.scss';

.appTableStats {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: $THEME_COLOR_SECONDARY;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @include desktopDown {
        overflow-x: scroll;
    }

    &.center {
        @include desktop {
            justify-content: center;
        }
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 15px;
        height: 5px;
        background-color: $THEME_COLOR_SECONDARY;
        border-radius: 5px;
    }

    &:before {
        left: -6px;
    }

    &::after {
        right: -6px;
    }

    h3 {
        font-size: $FONTSIZE_18;
        font-weight: bold;
        color: $COLOR_WHITE;
        white-space: nowrap;
    }

    .statsList {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 1;

        .statsItm {
            display: flex;
            align-items: center;

            @include desktopDown {
                margin-right: 40px;
                white-space: nowrap;

                &:last-child {
                    margin-right: 0;
                }
            }

            p,
            span {
                color: $COLOR_WHITE;
            }

            span {
                font-size: $FONTSIZE_20;
                margin-left: 5px;
            }

            &.denseItem {
                span {
                    font-size: $FONTSIZE_BODY;
                }
            }
        }
    }
}

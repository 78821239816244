@import 'styles/styles.scss';

.banner {
    margin-bottom: 20px;

    .gradientCard {
        display: flex;
    }

    .noPadding {
        padding: 0;
    }

    .carousel {
        .control-dots {
            .dot {
                width: 4px;
                height: 4px;
                margin: 0 4px;

                &.selected {
                    width: 20px;
                    border-radius: 12px;
                }
            }
        }
    }
}

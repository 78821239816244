@import 'styles/styles.scss';

.valueIconCard {
    &.card {
        &.MuiPaper-root {
            margin-bottom: 0;
        }
    }

    .cardContent {
        height: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;

        .value {
            grid-column: 1 / 2;
            font-size: $FONTSIZE_32;
            font-weight: $FONTWEIGHT_BOLD;
            @include desktopDown {
                font-size: $FONTSIZE_24;
            }
        }
        .label {
            grid-column: 1 / 2;
        }
        .icon {
            grid-column: 2;
            grid-row: 1 / 3;
            height: 50px;
            justify-self: flex-end;
            align-self: center;
            width: 60px;
            height: 60px;
        }
    }
}

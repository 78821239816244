@import 'styles/styles.scss';

.confirmCard {
    color: $COLOR_WHITE;
    background-color: $THEME_COLOR_SECONDARY;
    padding: 20px 30px;
    border: 1px solid $COLOR_WHITE;
    box-shadow: 0px 0px 6px rgba($THEME_COLOR_SECONDARY, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;

    .confirmItm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }

        .value {
            text-align: right;
        }
    }
}

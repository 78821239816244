@import 'styles/styles.scss';

.twoLevelOrderedList {
    display: flex;
    flex-direction: column;

    .listSection {
        .titleTxt {
            font-weight: $FONTWEIGHT_BOLD;
            font-size: $FONTSIZE_18;
            color: $THEME_COLOR_SECONDARY;
            margin-bottom: 30px;

            @include tabletDown {
                font-size: $FONTSIZE_16;
            }
        }

        .content {
            font-weight: $FONTWEIGHT_NORMAL;
            font-size: $FONTSIZE_16;
            color: $THEME_COLOR_SECONDARY;

            ol {
                padding-left: 0;

                li {
                    padding-left: 0;
                }

                &.customMarker {
                    counter-reset: list;

                    & > li:before {
                        counter-increment: list;
                    }

                    & > li {
                        list-style: none;
                        line-height: 1.4;
                    }
                }

                &.outerLevel {
                    font-weight: $FONTWEIGHT_BOLD;
                    font-size: $FONTSIZE_18;

                    .outerItem {
                        margin-bottom: 30px;
                    }

                    & > li:before {
                        margin-right: 6px;
                        content: '(' counter(list, lower-alpha) ') ';
                    }
                }

                &.innerLevel {
                    font-weight: $FONTWEIGHT_NORMAL;
                    margin-top: 20px;
                    font-size: $FONTSIZE_BODY;

                    & > li:before {
                        content: counter(list) '. ';
                        margin-right: 10px;
                    }

                    & > li {
                        display: flex;
                        align-items: baseline;

                        & > span {
                            line-height: 1.3;
                        }
                    }
                }
            }
        }
    }
}

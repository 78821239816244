.fieldRightAdornment {
    .btnRightAdornment {
        height: 18px;
        cursor: pointer;
    }

    .cancelIco {
        width: 18px;
        height: 18px;
    }
}

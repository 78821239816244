@import 'styles/styles.scss';

.walletForm {
    width: 450px;
    display: flex;
    flex-direction: column;
    @include flexRowGap(20px);
    @include desktopDown {
        @include flexRowGap(12px);
        width: 100%;
    }

    button[type='submit'] {
        margin-top: 40px;
    }
}

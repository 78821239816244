@import 'styles/styles.scss';

$SELECTED_TAB_MARGIN: 2px;
$ROUND_TAB_BORDER_RADIUS: 7px;
$PAGE_TAB_HEIGHT: 32px;
$CARD_TAB_HEIGHT: calc(48px - #{$SELECTED_TAB_MARGIN});
$HEADER_TAB_HEIGHT: calc(32px - #{$SELECTED_TAB_MARGIN});
$SEGMENT_TAB_HEIGHT: 30px;
$SEGMENT_TAB_INNER_PADDING: 4px;

@mixin divider($width, $height, $backgroundColor) {
    .divider {
        padding: 0;
        min-width: $width;
        width: $width;
        min-height: inherit;
        &.hidden {
            visibility: hidden;
            transition: all 0.25s;
        }
        .MuiTouchRipple-root {
            margin: auto;
            width: $width;
            height: $height;
            border-radius: 0.5px;
            background-color: $backgroundColor;
        }
    }
}

@mixin commonIndicatorStyle($color, $width, $allowTransition: true) {
    .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;
        @if (not $allowTransition) {
            transition: none;
        }
    }
    .tabIndicator {
        width: $width;
        background-color: $color;
    }
}

@mixin commonTabStyle() {
    max-width: unset;
    flex-grow: 1;
    line-height: 1;
    padding: 0 16px;
    text-transform: none;
}

@mixin roundCornerTabBarStyle($topBordersRadius: 0, $bottomBordersRadius: 0) {
    padding: 0 #{$SELECTED_TAB_MARGIN} 0 #{$SELECTED_TAB_MARGIN};
    &.MuiTabs-root {
        border-radius: $topBordersRadius $topBordersRadius $bottomBordersRadius
            $bottomBordersRadius;
    }
}

@mixin roundCornerTabStyle($unselectedColor, $selectedBackgroundColor) {
    &.MuiTab-root {
        font-size: $FONTSIZE_16;
        color: $unselectedColor;
        padding-top: #{$SELECTED_TAB_MARGIN};
        padding-bottom: 0;
        text-transform: none;
    }
    &.Mui-selected {
        font-weight: $FONTWEIGHT_BOLD;
        color: $THEME_COLOR_PRIMARY;
        background-color: $selectedBackgroundColor;
        border-radius: $ROUND_TAB_BORDER_RADIUS $ROUND_TAB_BORDER_RADIUS 0 0;
        padding-top: 0px;
        margin-top: #{$SELECTED_TAB_MARGIN};
        box-shadow: 0px 3px 8px rgba($COLOR_BLACK, 0.12),
            0px 3px 1px rgba(154, 104, 104, 0.04);
    }
}

.tabControl {
    position: relative;
}

.pageTab {
    @include commonIndicatorStyle($THEME_COLOR_PRIMARY, 112px);
    background-color: $THEME_COLOR_SECONDARY;

    &.MuiTabs-root {
        min-height: $PAGE_TAB_HEIGHT;
    }

    @include tabArrowBtn($THEME_COLOR_SECONDARY, $THEME_COLOR_SECONDARY);

    .tab {
        @include commonTabStyle();
        min-height: $PAGE_TAB_HEIGHT;
        color: $COLOR_WHITE;
        font-size: $FONTSIZE_12;

        &.Mui-selected {
            font-weight: $FONTWEIGHT_BOLD;
        }
    }

    @include divider(2px, 20px, rgba($COLOR_WHITE, 0.36));
}

.cardTab {
    @include commonIndicatorStyle($COLOR_WHITE, calc(100% - 4px));
    @include roundCornerTabBarStyle($ROUND_TAB_BORDER_RADIUS);
    background-color: $COLOR_DIRTY_SNOW;

    &.MuiTabs-root {
        min-height: $CARD_TAB_HEIGHT;
    }

    @include tabArrowBtn($COLOR_DIRTY_SNOW, $THEME_COLOR_SECONDARY);

    .tabIndicator {
        position: absolute;
        width: 100%;
        height: $CARD_TAB_HEIGHT;
        bottom: 0px;
        border-radius: $ROUND_TAB_BORDER_RADIUS $ROUND_TAB_BORDER_RADIUS 0 0;
        box-shadow: 0px 3px 8px rgba($COLOR_BLACK, 0.12),
            0px 3px 1px rgba(154, 104, 104, 0.04);

        &:after {
            content: '';
            width: 100%;
            display: flex;
            height: 5px;
            position: absolute;
            bottom: 0;
            background: linear-gradient(
                0deg,
                $THEME_COLOR_PRIMARY,
                #ffb348 97.06%
            );
            border-radius: 4px 4px 0px 0px;
        }
    }
    .tab {
        @include commonTabStyle();
        min-height: $CARD_TAB_HEIGHT;
        margin-top: #{$SELECTED_TAB_MARGIN};
        font-size: $FONTSIZE_BODY;
        z-index: $Z_INDEX_WITHIN_COMPONENT_1;
        color: $COLOR_GREY;

        &.Mui-selected {
            font-weight: $FONTWEIGHT_BOLD;
            color: $THEME_COLOR_PRIMARY;
            border-radius: $ROUND_TAB_BORDER_RADIUS $ROUND_TAB_BORDER_RADIUS 0 0;
            padding-top: 0px;
        }
    }

    @include divider(3px, 26px, rgba($THEME_COLOR_SECONDARY, 0.36));
}

.headerTab {
    @include commonIndicatorStyle(
        $THEME_COLOR_SECONDARY,
        calc(100% - 4px),
        false
    );
    @include roundCornerTabBarStyle($ROUND_TAB_BORDER_RADIUS);
    background-color: $COLOR_GREY_JUDAH_SILK;

    &.MuiTabs-root {
        min-height: $HEADER_TAB_HEIGHT;
    }
    .tab {
        @include commonTabStyle();
        @include roundCornerTabStyle($COLOR_WHITE, $THEME_COLOR_SECONDARY);
        min-height: $HEADER_TAB_HEIGHT;
    }

    @include divider(1px, 16px, rgba($COLOR_ORANGE, 0.36));
}

.segmentTab {
    @include roundCornerTabBarStyle(
        $ROUND_TAB_BORDER_RADIUS,
        $ROUND_TAB_BORDER_RADIUS
    );
    @include commonIndicatorStyle(
        $COLOR_WHITE,
        calc(100% - calc(#{$SEGMENT_TAB_INNER_PADDING} / 2))
    );
    background-color: rgba($THEME_COLOR_PRIMARY, 0.15);

    .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;
    }
    .tabIndicator {
        position: absolute;
        height: calc(#{$SEGMENT_TAB_HEIGHT} - #{$SEGMENT_TAB_INNER_PADDING});
        top: calc(-#{$SEGMENT_TAB_HEIGHT} + #{$SEGMENT_TAB_INNER_PADDING});
        border-radius: $ROUND_TAB_BORDER_RADIUS;
        box-shadow: 0px 3px 8px rgba($COLOR_BLACK, 0.12),
            0px 3px 1px rgba($COLOR_BLACK, 0.04);
    }
    &.MuiTabs-root {
        min-height: $SEGMENT_TAB_HEIGHT;
    }

    @include tabArrowBtn($COLOR_WHITE, $THEME_COLOR_SECONDARY);

    .tab {
        @include commonTabStyle();
        font-size: 13px;
        min-height: $SEGMENT_TAB_HEIGHT;
        text-transform: none;
        z-index: $Z_INDEX_WITHIN_COMPONENT_1;

        &.Mui-selected {
            font-weight: $FONTWEIGHT_BOLD;
        }
    }

    @include divider(1px, 15px, rgba($COLOR_GREY_JUDAH_SILK, 0.36));
}

@mixin desktop {
    @media screen and (min-width: #{$desktop}px) {
        @content;
    }
}

@mixin desktopDown {
    @media screen and (max-width: #{$desktop - 1}px) {
        @content;
    }
}

@mixin laptop {
    @media screen and (min-width: #{$laptop}px) and (max-width: #{$desktop - 1}px) {
        @content;
    }
}
@mixin laptopDown {
    @media screen and (max-width: #{$laptop - 1}px) {
        @content;
    }
}
@mixin laptopUp {
    @media screen and (min-width: #{laptop}px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: #{$tablet}px) and (max-width: #{$laptop - 1}px) {
        @content;
    }
}
@mixin tabletDown {
    @media screen and (max-width: #{$tablet - 1}px) {
        @content;
    }
}
@mixin tabletUp {
    @media screen and (min-width: #{$tablet}px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$tablet - 1}px) {
        @content;
    }
}

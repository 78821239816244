@import 'styles/styles.scss';

.stepperDotStepIndex {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    font-size: $FONTSIZE_28;
    background-color: $COLOR_WHITE;
    border-radius: 50%;

    @include mobile {
        width: 30px;
        height: 30px;
    }

    &.circle {
        border: 5px solid $COLOR_BRIGHT_GREY;

        @include mobile {
            border-width: 2px;
        }
    }
    &.orangeCircle {
        border-radius: 50%;
        border: 5px solid $THEME_COLOR_PRIMARY;

        @include mobile {
            border-width: 2px;
        }
    }

    .fileIco {
        position: absolute;
        width: 23px;
        height: 30px;
        z-index: $Z_INDEX_WITHIN_COMPONENT_0;

        @include mobile {
            width: 15px;
            height: 18px;
        }
    }

    .statusIco {
        position: absolute;
        width: 100%;
        height: 100%;

        svg {
            width: 60px;
            height: 60px;
        }
    }

    .stepIndexTxt {
        font-size: $FONTSIZE_18;
        font-weight: $FONTWEIGHT_BOLD;
        color: $COLOR_WHITE;
        margin-top: 5px;
        z-index: $Z_INDEX_WITHIN_COMPONENT_1;

        @include mobile {
            font-size: $FONTSIZE_12;
            margin-top: 2px;
        }
    }
}

@import 'styles/styles.scss';

@mixin commonBgStyle() {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;

    img {
        display: block;
        width: 100%;
    }
}

.authentication {
    position: relative;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/authentication/img_auth_bg.png');
    background-size: cover;

    &.manager {
        background-image: url('../../assets/images/authentication/img_manager_auth_bg.png');
    }

    .topBg {
        @include commonBgStyle();
        top: 0;

        @include desktopDown {
            display: none;
        }
    }

    .content {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        max-width: 1380px;
        margin: 0 auto;
        z-index: $Z_INDEX_WITHIN_COMPONENT_1;

        &.center {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: calc(100% - 300px);
        }

        @include desktopDown {
            margin: 0 12px;
        }

        // only for PublicMessage
        .desc {
            margin-bottom: 60px;
        }

        // only for PublicMessage
        .completeBtn {
            width: 100px;
        }

        .thumbnail {
            width: 600px;
            margin-top: 40px;
            margin-right: 100px;

            @include desktopDown {
                display: none;
            }
        }

        .card {
            position: relative;
            width: 551px;

            @include desktop {
                height: 640px;
                background-image: url('../../assets/images/authentication/img_auth_card.png');
                background-size: cover;
            }

            @include desktopDown {
                width: 100%;
                padding: 30px 20px;
                background-color: $COLOR_WHITE;
                border-radius: 30px;
            }

            .cardContent {
                @include desktop {
                    position: absolute;
                    top: 54px;
                    left: 110px;
                    max-width: 378px;
                    max-height: 520px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .bottomBg {
        @include commonBgStyle();
        bottom: 0;
    }
}

@import 'styles/styles.scss';

.navbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $COLOR_WHITE;
    z-index: $Z_INDEX_HEADER;

    @include desktopDown {
        z-index: $Z_INDEX_HEADER_MOBILE;
    }
}

.mainWrapper {
    background-color: $THEME_COLOR_SECONDARY;

    @include desktopDown {
        top: 72px;
        padding-top: 12px;
    }

    .content {
        width: 100%;
    }
}

.contentWrapper {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;

    @include desktop {
        min-height: 879px;
    }

    @include desktopDown {
        padding: 0 12px;
    }

    .content {
        width: 100%;
    }
}

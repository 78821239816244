@import 'styles/styles.scss';

.cardManagementBoard {
    .carousel {
        margin-bottom: 20px;

        .slider-wrapper {
            .slide {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: unset;

                .gradientCard {
                    background: unset;
                    height: auto;
                    width: auto;
                    position: relative;

                    .cardContent {
                        background-color: unset;

                        > img {
                            max-width: 400px;
                            height: auto;
                            @include desktopDown {
                                max-width: 320px;
                                height: auto;
                            }
                            @include mobile {
                                max-width: 300px;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

        .control-dots {
            .dot {
                width: 4px;
                height: 4px;
                margin: 0 4px;
                background-color: $THEME_COLOR_SECONDARY;

                &.selected {
                    width: 20px;
                    border-radius: 12px;
                }
            }
        }
    }

    .tableWrapper {
        .appTableContainer {
            max-height: 400px;

            @include desktop {
                tr {
                    td:nth-child(1) {
                        *:not(.noContentBoard) {
                            max-width: 480px;
                            overflow: hidden;
                            white-space: normal;
                        }
                    }
                }
            }
        }
        .addCardFooterButton {
            margin-top: 10px;
        }
    }
}

@import 'styles/styles.scss';

.asset {
    position: relative;
    margin-top: 28px;
    padding: 16px;
    background-color: $THEME_COLOR_SECONDARY;
    box-shadow: 0px 0px 6px rgba($COLOR_WHITE, 0.2);
    border-radius: 16px 16px 10px 10px;

    @include desktopDown {
        border: 1px solid $COLOR_WHITE;
    }

    .invisbleBtn {
        position: absolute;
        top: 15px;
        right: 20px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .balance {
        display: flex;
        flex-direction: column;
        align-items: center;

        h4 {
            font-size: $FONTSIZE_20;
            color: $COLOR_SMOKEGREY;
        }

        h2 {
            font-size: $FONTSIZE_32;
            color: $COLOR_WHITE;
        }
    }

    hr {
        border-color: $COLOR_WHITE;
    }

    .detailList {
        margin-top: 8px;

        .detailItm {
            margin-bottom: 20px;
        }

        h5 {
            font-size: $FONTSIZE_16;
            color: $COLOR_SMOKEGREY;
        }

        p {
            color: $COLOR_WHITE;
        }
    }
}

@import 'styles/styles.scss';

footer {
    display: flex;
    justify-content: center;
    font-size: $FONTSIZE_BODY;
    color: $COLOR_STEELGREY;
    padding: 20px 0;
    background-color: $THEME_COLOR_SECONDARY;
    box-shadow: 0px 0px 40px 0px rgba($COLOR_WHITE, 0.1);

    .footerWrapper {
        max-width: $DESKTOP_BREAKPOINT;
        margin: 0 12px;

        .copyright {
            text-align: center;
        }
    }
}

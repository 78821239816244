@import 'styles/styles.scss';

.pageLayoutTopTab {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .appPageTab {
        &.tabControl {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            background-color: $THEME_COLOR_SECONDARY;
        }
    }
}

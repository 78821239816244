@import 'styles/styles.scss';

.basicInfo {
    margin-bottom: 25px;

    &.trade {
        .MuiPaper-root {
            padding: 0;

            .MuiCardHeader-root {
                margin-top: 20px;
                margin-left: 20px;
            }
        }
    }

    .managerButton {
        .MuiTypography-root {
            min-width: 110px;
        }
    }

    .MuiPaper-root {
        @include basicInfoBg();
        padding: 6px;

        .MuiCardHeader-root {
            margin-top: 16px;
            margin-left: 16px;
        }

        @include desktopDown {
            margin-bottom: 0;
        }
    }

    .basicInfoContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 35px;
    }

    .profilePic {
        width: 120px;
    }

    .greeting {
        text-align: center;

        h4 {
            font-size: $FONTSIZE_18;
            font-weight: $FONTWEIGHT_BOLD;
            margin: 15px 0 5px;
        }
    }

    .tradeAcSelect {
        display: flex;
        align-items: center;

        .MuiTypography-root {
            margin-right: 8px;
        }

        .inputFormControl {
            width: 140px;
        }
    }

    .currentTradeAc {
        text-align: center;
    }

    .tradeAccount {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .quickBtnWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .quickBtn {
            width: 230px;
            margin-top: 20px;
        }
    }

    .tradeAcDetail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        padding: 6px 16px;
        background-color: rgba($COLOR_ORANGE, 0.12);
        border-radius: 9px;

        span {
            font-weight: $FONTWEIGHT_MEDIUM;
        }

        hr {
            margin: 2px 8px;
            border-color: rgba($THEME_COLOR_SECONDARY, 0.36);
        }
    }
}

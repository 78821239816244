@import 'styles/styles.scss';

.confirmDialog {
    @include dialogDimension(970px, 800px);

    .dialogContent {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .confirmBoard,
    .completeModifyBoardWrapper {
        .appStatusContentWrapper {
            .infoCard {
                min-width: unset;
                margin: 40px 0 70px 0;
                background-color: $THEME_COLOR_SECONDARY;
                color: $COLOR_WHITE;
                .cardContentMargin {
                    @include flexRowGap(20px);
                }
                .sectionCardContentMargin {
                        @include flexRowGap(8px);
                }
                .sectionCardContent:not(:first-child){
                        border-top: 1px solid #fff;
                        padding-top: 8px;
                }
                .cardContent {
                    display: flex;
                    flex-direction: column;
                    .info {
                        display: flex;
                        justify-content: space-between;
                        p:nth-child(1) {
                            max-width: 45%;
                        }
                        p:nth-child(2) {
                            max-width: 50%;
                            overflow-wrap: break-word;
                            word-wrap: break-word;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}

@import 'styles/styles.scss';

.filterGroupFormWrapper {
    .filterGroupForm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 16px;

        @include desktopDown {
            margin-top: 0;
        }
    }

    .filterGroupWrapper {
        width: 100%;
        overflow: hidden;

        .advancedFilterList {
            margin-top: 0;
            max-height: 2000px; // To handle possible expanded heights, need to be hardcoded here
            transition: max-height 0.5s linear;

            &.collapsed {
                max-height: 0;
            }
        }
    }

    .filterFieldWrapper {
        margin-top: 8px;

        @include mobile {
            margin-top: 0;
        }

        &.appSelectField .MuiInputBase-root,
        .inputField {
            min-width: 160px;
        }
    }

    .btnGroupWrapper {
        display: flex;
        align-self: flex-end;
        margin-top: 12px;

        @include desktopDown {
            flex-direction: column-reverse;
            width: 100%;
        }

        .btnSubGroupWrapper {
            display: flex;
            align-items: flex-end;

            @include desktopDown {
                justify-content: space-between;
                width: 100%;
            }
        }

        .btnSubGroupPrimaryWrapper {
            margin-top: 10px;
        }

        .btn {
            margin-left: 16px;
            height: 32px;
            padding: 6px 20px;
            line-height: 1;

            @include desktopDown {
                margin-left: 0;
                width: 100%;
            }

            &.btnSearch,
            &.btnReset {
                @include desktopDown {
                    width: 49%;
                }
            }
        }
    }
}

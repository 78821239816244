@import 'styles/styles.scss';

.appLogoBar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1310px;
    margin: 0 auto;
    padding: 34px 0 40px;
    z-index: $Z_INDEX_HEADER;

    @include desktopDown {
        margin-bottom: 50px;
        padding: 10px;
    }

    .logo {
        width: 213px;
        cursor: pointer;

        @include desktopDown {
            width: 120px;
        }
    }

    .supplement {
        display: flex;
        align-items: center;

        @include desktopDown {
            margin-top: 10px;
        }

        .onlineCsBtn {
            @include desktopDown {
                order: 2;
            }

            .onlineCsIco {
                fill: $COLOR_ORANGE;

                @include desktopDown {
                    width: 15px;
                    height: 18px;
                }
            }

            .onlineCsTxt {
                @include desktopDown {
                    font-size: $FONTSIZE_12;
                }
            }
        }

        @include languageSwitch();
    }

    @include langMenu();

    .divider {
        border-color: $COLOR_WHITE;
        height: 12px;
        margin: 0 18px;

        @include desktopDown {
            margin: 0 10px;
        }

        &.black {
            border-color: $COLOR_DARKGREY;
        }
    }
    .btnWrapper {
        color: $COLOR_BLACK;
    }
}

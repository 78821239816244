@import 'styles/styles.scss';

.acStatusCard {
    .acStatusContent {
        display: flex;
        align-items: center;
        justify-content: center;

        @include desktopDown {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
    }

    .stepper {
        margin-top: 25px;

        @include desktopDown {
            margin-top: 10px;
            max-width: 100%;
            width: 100%;
        }
    }

    .btnProcess {
        @include desktopDown {
            margin-top: 16px;
        }
    }
}

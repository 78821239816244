@import 'styles/styles.scss';

.statusCard {
    &.card {
        &.MuiPaper-root {
            margin-bottom: 0;
        }
    }

    .MuiGrid-container {
        margin-top: 25px;

        @include tabletDown {
            margin-top: 10px;
        }
    }

    h2 {
        font-size: $FONTSIZE_16;
        font-weight: $FONTWEIGHT_BOLD;
    }

    h4 {
        font-size: $FONTSIZE_20;
        font-weight: $FONTWEIGHT_BOLD;

        @include tabletDown {
            font-size: $FONTSIZE_BODY;
        }

        &.blue {
            color: $COLOR_LIGHTBLUE;
        }

        &.green {
            color: $COLOR_LIGHTGREEN;
        }
    }

    p {
        color: $COLOR_DIRTY_SNOW;
    }
}

@import 'styles/styles.scss';

@mixin muiButton {
    border-radius: 8px;
    background-color: transparent;
    color: $COLOR_WHITE;

    &:disabled {
        color: $COLOR_ASH_GREY;
    }
    &.Mui-selected {
        color: $COLOR_WHITE;
        background-color: $THEME_COLOR_PRIMARY;
    }
    &:hover {
        background-color: rgba($THEME_COLOR_PRIMARY, 0.4);
    }
    &.MuiPickersDay-today {
        color: $THEME_COLOR_PRIMARY;
        border-color: $THEME_COLOR_PRIMARY;

        &.Mui-selected {
            color: $COLOR_WHITE;
        }
    }
}

.filterCalendar {
    .MuiTextField-root {
        border: 1px solid $COLOR_SMOKEGREY;
        border-radius: 10px;
        @include fieldBorderFocused();

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }
    }
}

.calendarPopover {
    .MuiPopover-paper {
        margin: 8px 0 8px 0;
        border-radius: 10px;
        background-color: transparent;

        .MuiPickerStaticWrapper-content {
            background-color: rgba($COLOR_BLACK, 0.8);
            backdrop-filter: blur(4px);

            .MuiPickersCalendarHeader-label {
                color: $COLOR_WHITE;
            }

            // Arrow buttons & day buttons
            .MuiButtonBase-root {
                @include muiButton;
            }

            .MuiDayPicker-weekDayLabel {
                color: $COLOR_SMOKEGREY;
            }
        }

        .MuiMonthPicker-root {
            .PrivatePickersMonth-root {
                @include muiButton;
            }
        }

        .MuiYearPicker-root {
            .PrivatePickersYear-yearButton {
                @include muiButton;
            }
        }
    }
}

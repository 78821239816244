@import 'styles/styles.scss';

.scrollSelect {
    margin-bottom: 10px;

    .tabItem {
        &.MuiTab-root {
            min-width: 120px;
            height: 40px;
            min-height: auto;
            margin: 5px;
            border-radius: 10px;
            box-shadow: 0px 0px 6px rgba($COLOR_BLACK, 0.2);
            border: 1px solid $COLOR_LIGHTGREY;
            color: $THEME_COLOR_SECONDARY;
            font-weight: $FONTWEIGHT_NORMAL;
            font-size: $FONTSIZE_18;
            text-transform: none;

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                background-color: $COLOR_ANTI_FLASH_WHITE;
            }

            @include desktopDown {
                font-size: $FONTSIZE_12;
                height: 34px;
                min-width: 90px;
                margin: 8px 10px 8px 0;
            }

            &.Mui-selected {
                font-weight: $FONTWEIGHT_BOLD;
                color: $COLOR_WHITE;
                background-color: $COLOR_ORANGE;
                border: 1px solid #ffb348;

                .roleLabel {
                    padding-top: 2px;
                    color: $COLOR_ORANGE;
                    background-color: $COLOR_WHITE;
                }
            }

            .selectContent {
                display: flex;
                align-items: center;
                font-size: $FONTSIZE_18;

                @include desktopDown {
                    font-size: $FONTSIZE_BODY;
                }
            }

            .roleLabel {
                margin-left: 8px;
                padding: 2px 5px;
                font-size: $FONTSIZE_10;
                color: $COLOR_WHITE;
                background-color: $COLOR_ORANGE;
                border-radius: 4px;
            }
        }
    }
}

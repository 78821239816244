@import 'styles/styles.scss';

.addCardFooterButton {
    display: flex;
    justify-content: center;

    .button {
        &.MuiButton-root {
            display: flex;
            align-items: center;
            margin-right: 12px;
            background-color: rgba($THEME_COLOR_SECONDARY, 0.05);
            width: 350px;
            height: 60px;
            border-radius: 5px;
            border: 1px solid rgba($THEME_COLOR_SECONDARY, 0.05);
        }

        .label {
            color: $THEME_COLOR_SECONDARY;
            font-size: $FONTSIZE_BODY;
        }

        &:hover {
            background-color: rgba($THEME_COLOR_SECONDARY, 0.2);
        }
    }
}

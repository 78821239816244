@import 'styles/styles.scss';

.card {
    &.MuiPaper-elevation1 {
        padding: 24px;
        margin-bottom: 30px;
        width: 100%;

        @include desktopDown {
            padding: 15px;
        }

        background-color: $COLOR_WHITE;
        border-radius: 10px;
        box-shadow: 0 2px 14px 0 rgba($COLOR_BLACK, 0.1);

        &.fullHeight {
            height: 100%;
        }
    }

    .cardHeaderWrapper {
        padding: 0;

        .MuiCardHeader-title {
            font-weight: $FONTWEIGHT_BOLD;
        }
    }

    .cardContent {
        padding: 0;

        &:last-child {
            padding-bottom: 0;
        }
    }

    .actionWrapper {
        cursor: pointer;

        .detailsBtn {
            display: flex;
            align-items: center;
        }
    }
}

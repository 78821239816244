@import 'styles/styles.scss';

.card {
    &.cardTabControl {
        &.MuiPaper-elevation1 {
            padding: 0;
            padding-bottom: 24px;
        }

        .tabContentWrapper {
            padding: 24px;

            @include desktopDown {
                padding: 15px;
            }
        }
    }
}

@import 'styles/styles.scss';

.qrCodeBoardWrapper {
    .qrCodeWrapper {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .qrCode {
        position: relative;
        padding: 20px;

        .frameTopL,
        .frameTopR,
        .frameBottomL,
        .frameBottomR {
            position: absolute;
            width: 20px;
        }

        .frameTopL {
            top: 0;
            left: 0;
        }

        .frameTopR {
            top: 0;
            right: 0;
            transform: rotate(90deg);
        }

        .frameBottomL {
            bottom: 0;
            left: 0;
            transform: rotate(-90deg);
        }

        .frameBottomR {
            bottom: 0;
            right: 0;
            transform: rotate(-180deg);
        }
    }

    .infoAuthKey {
        .infoLabel,
        .infoField {
            font-size: $FONTSIZE_BODY;
            font-weight: $FONTWEIGHT_SEMIBOLD;
        }

        .infoField {
            display: flex;
            align-items: center;
        }
    }
}

@import 'styles/styles.scss';

.navBtn {
    &.MuiButton-root {
        @include desktopDown {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
    }

    .MuiButton-endIcon {
        @include desktopDown {
            position: absolute;
            right: 0;
        }
    }

    .MuiTypography-root {
        @include desktop {
            max-width: 90px;
        }
    }

    .arrowDown {
        &.MuiSvgIcon-root {
            font-size: $FONTSIZE_BODY;

            @include desktopDown {
                font-size: $FONTSIZE_20;
            }
        }
    }

    span {
        color: $COLOR_WHITE;
    }

    svg {
        fill: $COLOR_WHITE;

        &.profileIco {
            stroke: $COLOR_WHITE;
        }
    }
    &.selected {
        svg {
            fill: $COLOR_ORANGE;

            &.profileIco {
                stroke: $COLOR_ORANGE;
            }
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $COLOR_ORANGE;

            @include desktopDown {
                display: none;
            }
        }
        span {
            font-weight: $FONTWEIGHT_BOLD;
            color: $COLOR_ORANGE;
        }
    }
}

.hoverMenu {
    &.MuiList-root {
        padding: 0;
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        @include desktop {
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -80px;
            opacity: 0;
            visibility: hidden;
            transform: translateY(100%);
            border-radius: 8px;
            background-color: rgba($COLOR_WHITE, 0.6);
            backdrop-filter: blur(15px);
            box-shadow: 0px 0px 10px 0px rgba($COLOR_BLACK, 0.3);
            transition: visibility 0s, opacity 0s;
        }

        li {
            padding: 3px 0;
            border-radius: 8px;
            cursor: pointer;

            @include desktop {
                text-align: center;
                width: 160px;
            }

            &:hover {
                background-color: rgba($COLOR_BLACK, 0.1);
            }

            &.Mui-selected {
                background: linear-gradient(
                    0deg,
                    rgba(243, 146, 0, 1) 0%,
                    rgba(255, 179, 72, 1) 97%
                );

                @include desktopDown {
                    background: transparent;
                }

                .submenuLabel {
                    font-weight: bold;
                    color: $COLOR_WHITE;

                    @include desktopDown {
                        color: $COLOR_ORANGE;
                    }
                }
            }

            .submenuLabel {
                width: 100%;
                padding: 6px 8px;
                color: $THEME_COLOR_SECONDARY;
                font-size: $FONTSIZE_16;
                line-height: 1.5;

                @include desktopDown {
                    color: $COLOR_WHITE;
                    margin-left: 40px;
                }
            }
        }
    }
}

.menuDrawer {
    .drawerCloseBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        color: $COLOR_WHITE;
    }
}

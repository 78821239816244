@import 'styles/styles.scss';

.downloadList {
    .downloadItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 50px;
        margin-bottom: 24px;
        padding-left: 30px;
        background-color: $COLOR_BLACK;
        border: 1px solid $COLOR_WHITE;
        border-radius: 5px;

        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            border-color: $COLOR_WHITE;
        }

        .MuiTouchRipple-child {
            background-color: $COLOR_WHITE;
        }

        .platformIco {
            width: 28px;
            margin-right: 18px;
        }

        .MuiTypography-root {
            color: $COLOR_WHITE;
            text-align: left;
        }
    }
}

@import 'styles/styles.scss';

.plainTextMaskableField {
    display: flex;
    flex-direction: column;

    .fieldValueWrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .MuiInputAdornment-root {
        margin-left: 0;
        height: 100%;
    }
}

@import 'styles/styles.scss';

.appTopContainer {
    width: 980px;
    padding: 30px 0;

    &.fullScreen {
        width: 100%;
    }
}

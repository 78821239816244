@import 'styles/styles.scss';

.stepper.stepperLineVertical {
    .step {
        .stepItm {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $THEME_COLOR_SECONDARY;
            height: 30px;

            .stepIndex {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                flex: none;
                width: 50px;
                height: 50px;
                font-size: $FONTSIZE_24;
                font-weight: $FONTWEIGHT_SEMIBOLD;
                border-radius: 50%;
                border: 3px solid $THEME_COLOR_PRIMARY;
                color: $THEME_COLOR_PRIMARY;
                background-color: $COLOR_WHITE;

                @include tabletDown {
                    width: 35px;
                    height: 35px;
                    font-size: $FONTSIZE_BODY;
                    border-width: 2px;
                }
            }

            .stepName {
                font-size: 14px;
                min-width: 100px;
                margin-left: 63px;
                font-size: $FONTSIZE_BODY;

                @include mobile {
                    font-size: $FONTSIZE_12;
                    margin-left: 25px;
                }
            }
        }

        .stepVerticalLine {
            width: 3px;
            height: 60px;
            margin-left: 25px;
            background-color: $THEME_COLOR_PRIMARY;

            @include mobile {
                width: 2px;
                height: 50px;
                margin-left: 16px;
            }
        }
    }
}

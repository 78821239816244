@import 'styles/styles.scss';

.commissionTableWrapper {
    margin-bottom: 32px;

    .commissionTableContainerWrapper {
        display: flex;
        justify-content: center;

        @include desktopDown {
            flex-direction: column;
        }

        .selectDropdownField .MuiSelect-select {
            height: 30px;
            line-height: 30px;
        }
    }

    .tableTitleRow {
        background-color: $COLOR_SMOKEGREY;
        height: 60px;

        th {
            font-weight: $FONTWEIGHT_SEMIBOLD;
        }
    }

    .tableSubTitleRow {
        background-color: $COLOR_PEARL;

        th {
            font-weight: $FONTWEIGHT_SEMIBOLD;
            padding: 10px;
        }
    }

    .tableBodyRow {
        height: 80px;
        @include desktopDown {
            height: 50px;
        }
    }

    .cellInnerWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    td {
        @include desktopDown {
            &:first-child {
                width: 20%;
            }
        }
    }

    td,
    th {
        border: 1px solid $COLOR_STEELGREY;

        @include desktopDown {
            padding: 0;
        }
    }

    .remark {
        margin-top: 26px;
        margin-left: 26px;
    }
}

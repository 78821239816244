@import 'styles/styles.scss';

.shortcut {
    position: relative;
    background-image: url('../../../../../assets/images/common/sidebar/shortcut/img_shortcutBg.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    border-radius: 10px;
    margin-top: 25px;
    padding: 16px;

    &.manager {
        background-image: none;
        box-shadow: 0 0 5px rgba($COLOR_BLACK, 0.3);
        padding: 0;
        overflow: hidden;

        h2 {
            display: inline-block;
            width: 90%;
            background-image: url('../../../../../assets/images/common/sidebar/img_shortcut_header.png');
            background-repeat: no-repeat;
            background-position: -10px top;
            background-size: cover;
            padding: 10px 16px;
        }

        .shortcutList {
            padding: 16px;
        }

        .shortcutItmContent {
            p {
                color: $THEME_COLOR_SECONDARY;
            }
        }
    }

    .dashboardThumb {
        position: absolute;
        top: 10px;
        right: 14px;
    }

    h2 {
        font-size: $FONTSIZE_16;
        color: $COLOR_WHITE;
    }

    .shortcutList {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
    }

    .shortcutItm {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 33.333%;
        margin: 10px 0;
    }

    .shortcutItmContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            color: $COLOR_WHITE;
            margin-top: 4px;
            text-align: center;
        }
    }
}

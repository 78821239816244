@import 'styles/styles.scss';

.bankCardForm {
    width: 330px;
    // display: flex;
    // flex-direction: column;
    @include flexRowGap(20px);
    @include desktopDown {
        @include flexRowGap(12px);
        width: 100%;
    }

    button[type='submit'] {
        margin-top: 40px;
    }

    .showCustomBankBtn {
        margin-top: -20px;
    }
}

@import 'styles/styles.scss';

.dialogContentRemind {
    .message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 125px;
        font-size: $FONTSIZE_16;
        margin-bottom: 25px;
    }

    .buttonRow {
        display: flex;
        justify-content: space-around;
        .button {
            width: 150px;
        }
    }
}

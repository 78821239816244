@import 'styles/styles.scss';

.gradientCard {
    width: 100%;
    background: rgb(0, 89, 184);
    background: linear-gradient(
        90deg,
        rgba(0, 89, 184, 1) 0%,
        rgba(8, 27, 49, 1) 100%
    );
    border-radius: 10px;
    padding: 1px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px 0px rgba($COLOR_WHITE, 0.2);

    &.animation {
        animation-name: cardGlowing;
        animation-duration: 1.5s;
        animation-iteration-count: 5;
        animation-delay: 1.5s;
    }

    .cardContent {
        width: 100%;
        padding: 24px;
        background-color: $THEME_COLOR_SECONDARY;
        border-radius: 10px;
        overflow: hidden;
    }
}

@keyframes cardGlowing {
    0% {
        box-shadow: 0px 0px 10px 0px rgba($COLOR_WHITE, 0.2);
    }
    50% {
        box-shadow: 0px 0px 25px 0px rgba($COLOR_WHITE, 0.8);
    }
    100% {
        box-shadow: 0px 0px 10px 0px rgba($COLOR_WHITE, 0.2);
    }
}

@mixin headerBeforeDecoration($width, $marginRight) {
    &:before {
        content: '';
        min-width: $width;
        align-self: stretch;
        margin: -4px $marginRight -4px 0px;
        background-color: $COLOR_ORANGE;
        border-radius: 2px;
    }
}

@mixin separatorVertical($color, $height: 28px) {
    &:after {
        content: '';
        background: $color;
        width: 1px;
        height: $height;
        margin-left: 8px;
    }
}

@mixin scrollbarThemed {
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-image: linear-gradient(180deg, #ffdab4 0%, #f39200 100%);
        border-radius: 6px;
    }

    // For Firefox
    scrollbar-color: #f39200 #f2f2f2;
}

@mixin basicInfoBg {
    background-image: url('../../assets/images/common/sidebar/img_wave.png');
    background-repeat: no-repeat;
    background-position: center 8%;
    background-size: 100% auto;
}

@mixin tabArrowBtn($bgColor, $iconColor) {
    .MuiIconButton-root {
        position: absolute;
        top: 0;
        width: 30px;
        height: 100%;
        background-color: $bgColor;
        border-radius: 0;
        z-index: $Z_INDEX_WITHIN_COMPONENT_9;

        &.arrowForward {
            right: 0;
            padding-right: 0;
            justify-content: flex-end;
            background: linear-gradient(
                90deg,
                rgba($bgColor, 0) 0%,
                rgba($bgColor, 1) 100%
            );
        }

        &.arrowBack {
            left: 0;
            padding-left: 0;
            justify-content: flex-start;
            background: linear-gradient(
                90deg,
                rgba($bgColor, 1) 0%,
                rgba($bgColor, 0) 100%
            );
        }

        svg {
            color: $iconColor;
        }
    }
}

@mixin langMenu {
    .langMenu {
        &.MuiList-root {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 150px;
            opacity: 0;
            visibility: hidden;
            transform: translateY(100%);
            z-index: $Z_INDEX_HEADER_MENU;
            border-radius: 8px;
            background-color: rgba($COLOR_WHITE, 0.6);
            backdrop-filter: blur(15px);
            box-shadow: 0px 0px 10px 0px rgba($COLOR_BLACK, 0.3);
            transition: visibility 0s, opacity 0s;

            @include desktop {
                left: 50%;
                margin-left: -75px;
            }

            li {
                cursor: pointer;
                &:hover {
                    background-color: rgba($COLOR_BLACK, 0.1);
                }

                &.selected {
                    color: $COLOR_WHITE;
                    background-color: $COLOR_ORANGE;
                }
            }
        }
    }
}

@mixin languageSwitch {
    .languageSwitch {
        @include desktopDown {
            margin-right: 10px;
            order: 1;
        }

        &:hover {
            .langMenu {
                visibility: visible;
                opacity: 1;
                height: auto;
                transition: visibility 0s, opacity 0.3s;
            }

            .arrowDownIco {
                transform: rotate(180deg) scaleX(-1);
            }
        }

        .langName {
            @include desktopDown {
                font-size: $FONTSIZE_12;
            }
        }

        .arrowDownIco {
            @include desktopDown {
                width: 18px;
                height: 18px;
            }
        }
    }
}

@import 'styles/styles.scss';

.textField {
    .inputField {
        .MuiInputBase-root {
            @include fieldStyle();
            // Override webkit autofill style
            input:-webkit-autofill {
                -webkit-box-shadow: 0 0 0 50px #{$COLOR_WHITE} inset;
                height: 10px;
            }
        }
    }
}

@import 'styles/styles.scss';

.tradingTool {
    .title {
        display: flex;
    }

    .tabWrapper {
        background: rgb(8, 27, 49);
        background: linear-gradient(
            90deg,
            rgba(8, 27, 49, 1) 0%,
            rgba(0, 89, 184, 1) 100%
        );
        height: 40px;
        padding: 1px;
        margin-bottom: 20px;
        border-radius: 7px;
        box-shadow: 0px 0px 10px rgba($COLOR_WHITE, 0.1);
    }

    .segmentTab {
        &.MuiTabs-root {
            height: 38px;
            background-color: #303453;
        }

        .tabIndicator {
            height: calc(38px - 4px);
            top: calc(-38px + 4px);
            background-color: $COLOR_ORANGE;
        }

        .tab {
            height: 38px;
            font-size: $FONTSIZE_16;
            color: $COLOR_WHITE;
        }
    }
}

@import 'styles/styles.scss';

.qrCodeBoardWrapper {
    height: 100%;

    .qrCodeWrapper {
        .qrCodeImageWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 22px;
            padding-top: 12px;
            padding-bottom: 12px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &.redFrameQRCodeWrapper {
            .commissionTypeNameTxt {
                color: $THEME_COLOR_PRIMARY;
            }
        }

        &.blueFrameQRCodeWrapper {
            .commissionTypeNameTxt {
                font-size: $FONTSIZE_18;
                font-weight: $FONTWEIGHT_BOLD;
                color: rgba(24, 53, 255, 80);
                margin-right: -20px; // Fix for last chinese character being chopped by Windows Firefox
                padding-bottom: 5px;
            }
        }
    }
}

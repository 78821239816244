@import 'styles/styles.scss';

#chat-widget-container.chat-widget-wrapper {
    width: 400px !important;
    top: auto;
    z-index: $Z_INDEX_WIDGET_LIVE_CHAT !important;

    &.chat-widget-hidden {
        visibility: hidden !important;
    }

    @include mobile {
        height: 100% !important;
        width: 100% !important;
    }
}

.tableCellTwoContentsWrapper {
    display: flex;
    align-items: center;

    svg {
        display: block;
        text-align: center;
    }
    .contentLeft {
        line-height: 100%;
    }
    .contentRight {
        margin-left: 10px;
        line-height: 100%;
    }
}

@import 'styles/styles.scss';

.loadingWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading {
        width: 50px;
    }
}

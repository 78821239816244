@import '../styles.scss';

@import './fundFlowSideContent.scss';
@import './scrollSelect.scss';

* {
    margin: 0;
    padding: 0;
    line-height: 1;
    box-sizing: border-box;
}

#root {
    background-color: $THEME_COLOR_SECONDARY;
}

ol {
    padding-left: 20px;
    li {
        padding-left: 10px;
        margin-bottom: 10px;
    }
}

html,
body {
    font-family: $FONT_PRIMARY;
    font-size: $FONTSIZE_BODY;
    font-weight: $FONTWEIGHT_NORMAL;
    color: $THEME_COLOR_SECONDARY;
    scroll-behavior: smooth;
    -webkit-text-size-adjust: none;
}

a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-decoration: none;
}

.hide {
    display: none !important;
}

.onlyDesktop {
    @include desktopDown {
        display: none !important;
    }
}

.onlyMobile {
    @include desktop {
        display: none !important;
    }
}

.w100 {
    width: 100% !important;
}

img {
    display: block;
    width: 100%;
}

.wrapper {
    position: relative;
    margin: 0 auto;
}

.registration {
    .headerWrapper {
        background-color: $THEME_COLOR_SECONDARY;
        padding-bottom: 48px;
    }
}

.cardHeaderWrapper {
    .titleWrapper {
        &.white {
            color: $COLOR_WHITE;
        }
    }
}

.boxContent {
    padding: 10px 16px;
    border: 1px solid $COLOR_BRIGHT_GREY;
    overflow: scroll;
}

// MUI Select MenuList (SelectDropdownField & ComboBoxField)
.selectMenuModal .MuiList-root.MuiList-padding.MuiMenu-list,
.MuiAutocomplete-popper > .MuiPaper-root > .MuiAutocomplete-listbox {
    // max-height: 250px;
}

// MUI Form Error Message
.MuiFormHelperText-root {
    color: $COLOR_DANGERRED !important;
}

.danger {
    color: $COLOR_DANGERRED !important;
}

.appLink {
    color: $THEME_COLOR_PRIMARY;
    text-decoration: underline;
}

.dialogLeadAddForm.dialogWrapper {
    .MuiPaper-root {
        max-width: 800px;
        width: 100%;
    }
}

.uppercase {
    text-transform: uppercase !important;
}

.bold {
    font-weight: $FONTWEIGHT_BOLD !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

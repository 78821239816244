@import 'styles/styles.scss';

.btnMoreDetail {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $THEME_COLOR_PRIMARY;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: $FONTSIZE_12;
    color: $THEME_COLOR_PRIMARY;
    margin-left: 5px;
    font-weight: $FONTWEIGHT_BOLD;
    background-color: $COLOR_WHITE;

    @include mobile {
        width: 12px;
        height: 12px;
        font-size: $FONTSIZE_10;
        margin-left: 2px;
        margin-right: 5px;
    }

    &:hover {
        cursor: pointer;
        background-color: buttonface;
    }

    .MuiTouchRipple-root {
        position: static;
    }
}

.popoverCommissionDetail {
    pointer-events: none;

    .MuiPaper-root {
        padding: 15px;
    }

    .title {
        display: flex;
        align-items: center;
        color: $COLOR_BLACK;
        font-size: $FONTSIZE_16;

        @include mobile {
            font-size: $FONTSIZE_BODY;
        }

        .lightBulbIco {
            margin-right: 6px;
            width: 18px;
            height: 18px;
        }
    }
    .tableWrapper {
        margin-top: 10px;

        .appTable {
            border-spacing: 0px;
        }
        .appTableHead {
            .MuiTableCell-head {
                background-color: $THEME_COLOR_SECONDARY;
                color: $COLOR_WHITE;
            }
        }

        .appTableHead th.MuiTableCell-head,
        .appTableBody td.MuiTableCell-root {
            border: 1px solid $COLOR_SMOKEGREY;
            border-radius: 0;
            font-size: $FONTSIZE_BODY;

            @include mobile {
                font-size: $FONTSIZE_12;
                padding: 10px;
            }
        }
    }
}

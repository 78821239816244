@import 'styles/styles.scss';

.sectionNotes {
    .title {
        color: $COLOR_WHITE;
        background-color: $THEME_COLOR_SECONDARY;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;

        font-size: $FONTSIZE_18;
        font-weight: $FONTWEIGHT_BOLD;

        @include tabletDown {
            font-size: $FONTSIZE_16;
        }
    }
    .descriptionList {
        margin-top: 20px;
        color: $THEME_COLOR_SECONDARY;

        li {
            line-height: 1.3;
        }
    }
}

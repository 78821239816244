@import 'styles/styles.scss';

.cardHeaderWrapper {
    margin-bottom: 20px;

    &.MuiCardHeader-root {
        padding: 0;

        @include tabletDown {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    .MuiCardHeader-action {
        align-self: center;
        margin-top: 0;
        margin-right: 0;

        @include tabletDown {
            margin-top: $SPACING_LAYOUT_SMALL;
            align-self: auto;
        }
    }

    .titleWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0;
        color: $COLOR_DARKGREY;
        font-size: $FONTSIZE_24;
        font-weight: $FONTWEIGHT_BOLD;
        line-height: 1;

        @include tabletDown {
            font-size: $FONTSIZE_20;
        }

        &.white {
            color: $COLOR_WHITE;
        }

        @include headerBeforeDecoration(4px, 8px);
    }
}

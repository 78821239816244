@import 'styles/styles.scss';
@import '../../mixin.scss';

.muiSelect {
    .MuiSelect-select {
        display: flex;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        box-sizing: border-box;
        background-color: transparent !important;
        border-right: 20px solid transparent;
        @include selectOptionHeight();

        .placeholderText {
            font-size: $FONTSIZE_16;
            color: $COLOR_FIELD_PLACEHOLDER;
        }
    }
}

.selectMenuModal {
    max-height: 300px;
    @include popupList('.MuiPaper-root', '.MuiMenuItem-root');

    .MuiPaper-root {
        margin: 10px 0;

        .MuiList-root {
            .MuiMenuItem-root {
                height: 33px;

                &.placeholder {
                    display: none;
                }

                &:first-child {
                    margin-top: 8px;
                }
                &:last-child {
                    position: relative;
                    &::after {
                        position: absolute;
                        content: '';
                        bottom: -8px;
                        width: 8px;
                        height: 100%;
                    }
                }
            }
        }
    }
}

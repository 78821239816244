@import 'styles/styles.scss';

.tableWrapper {
    margin-top: 20px;

    @include desktopDown {
        margin-top: 10px;
    }

    .appTableContainer {
        .indiana-scroll-container {
            @include scrollbarThemed();
        }
    }

    .appTable {
        overflow-x: auto;
        border-spacing: 0px 10px;
        border-collapse: separate;

        &.appTableWithHeader {
            margin-top: -10px;
        }
    }

    .appTableCellGroupStickyWrapper {
        padding-right: 4px;
        padding-left: 8px;

        .appTableCellGroupStickyInnerWrapper {
            display: flex;
            align-items: center;
            width: 100%;

            .MuiTableCell-root {
                border-bottom: none;
            }
        }
    }

    .MuiTableRow-head {
        .appTableCellGroupStickyInnerWrapper {
            @include separatorVertical($COLOR_WHITE);
        }

        .MuiTableCell-head {
            font-size: $FONTSIZE_BODY;
            font-weight: $FONTWEIGHT_BOLD;
            background-color: $COLOR_ANTI_FLASH_WHITE;
            border: none;
            white-space: nowrap;

            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        .tableCellActionGroup {
            padding-left: 8px;
        }
    }

    .appTableBody {
        .appTableRow {
            &.appTableRowHighLighted {
                background-color: $COLOR_LIGHTGREY;
            }

            &.rowCollapsible {
                .MuiTableCell-root {
                    padding: 0;

                    .expandedContent {
                        @include tableCell;
                    }
                }
            }
        }
        .appTableCellGroupStickyWrapper {
            height: 100%;
        }

        .appTableCellGroupStickyInnerWrapper {
            @include separatorVertical($COLOR_BRIGHT_GREY);
        }

        .MuiTableCell-root {
            @include tableCell;
            font-size: $FONTSIZE_BODY;
            border-top: 1px solid $COLOR_BRIGHT_GREY;
            border-bottom: 1px solid $COLOR_BRIGHT_GREY;
            white-space: nowrap;

            &:first-child {
                border-left: 1px solid $COLOR_BRIGHT_GREY;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-right: 1px solid $COLOR_BRIGHT_GREY;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            &.arrowIcoCell {
                max-width: 50px;

                svg {
                    cursor: pointer;
                }
            }
        }
    }

    .stickyTableCell {
        border-bottom: none;
        flex: 1;
        display: flex;

        &:not(:first-child) {
            margin-left: 10px;
        }

        @include desktopDown {
            justify-content: space-evenly;
        }
    }

    .sectionDashLinWrapper {
        &.btnTableFooter {
            margin-top: 8px;
        }
    }

    .tableCell {
        &.balance,
        &.equity {
            min-width: 100px;
        }
    }
}

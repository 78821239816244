@import 'styles/styles.scss';

@mixin selectOptionHeight() {
    height: 43px !important;
    line-height: 43px !important;

    @include desktopDown {
        height: 38px !important;
        line-height: 38px !important;
    }
}

@mixin placeholderTxt() {
    display: inline-block;
    font-size: $FONTSIZE_16;
    color: $COLOR_FIELD_PLACEHOLDER;
    // -webkit-text-fill-color: $COLOR_FIELD_PLACEHOLDER;
}

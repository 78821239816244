@import 'styles/styles.scss';

$STEPPER_WIDTH: 180px;

.stepper.stepperDot {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;

    @include desktopDown {
        width: auto;
    }

    .step {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        flex: 1;
        // width: $STEPPER_WIDTH;
        font-size: $FONTSIZE_18;
        color: $COLOR_BLACK;

        @include desktopDown {
            width: 100%;
        }

        @include laptopDown {
            font-size: $FONTSIZE_BODY;
        }

        .stepConnector {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 28px;
            z-index: $Z_INDEX_WITHIN_COMPONENT_1;

            @include desktopDown {
                margin-bottom: 10px;
            }
        }

        .stepHorizontalLine {
            position: absolute;
            top: 26px;
            width: 105%;
            // width: calc(#{$STEPPER_WIDTH} + 10px);
            height: 8px;
            background-color: $COLOR_BRIGHT_GREY;
            border-radius: 5px;
            z-index: $Z_INDEX_WITHIN_COMPONENT_0;

            @include mobile {
                top: 13px;
                height: 5px;
            }
        }

        .stepName {
            text-align: center;
            color: $COLOR_CHINESE_SILVER;
        }

        &.currStep,
        &.completedStep {
            opacity: 1;

            .stepConnector {
                .stepIndex {
                    border-color: $THEME_COLOR_PRIMARY;
                    z-index: $Z_INDEX_WITHIN_COMPONENT_9;
                }
            }

            .stepHorizontalLine {
                background-color: $THEME_COLOR_PRIMARY;
                z-index: $Z_INDEX_WITHIN_COMPONENT_1;
            }

            .stepName {
                &.black {
                    color: $COLOR_BLACK;
                }

                &.white {
                    color: $COLOR_WHITE;
                }
            }
        }
    }
}

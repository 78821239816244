@import 'styles/styles.scss';

.selectClientGroupPopUpField {
    .Mui-disabled.MuiInputBase-root {
        background-color: $COLOR_WHITE !important;

        .Mui-disabled.MuiSelect-select.MuiInputBase-input {
            cursor: pointer;
        }
    }

    .Mui-disabled.MuiSvgIcon-root.MuiSelect-icon {
        color: rgba($COLOR_BLACK, 0.54);
    }

    &.unselected {
        .Mui-disabled.MuiInputBase-root {
            @include fieldDisabled();

            .MuiSvgIcon-root {
                color: rgba($COLOR_BLACK, 0.26);
            }

            .Mui-disabled.MuiSelect-select.MuiInputBase-input {
                cursor: default;
            }
        }

        .groupNameTxt {
            opacity: 1;
            -webkit-text-fill-color: rgba($COLOR_BLACK, 0.38);
        }
    }
}

@import 'styles/styles.scss';

.paginationWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: $FONTSIZE_BODY;

    .paginationLabel {
        color: $COLOR_SMOKEGREY;
    }

    .MuiInputBase-root {
        @include desktopDown {
            margin-right: 0;
        }
    }

    .MuiTablePagination-select {
        font-size: $FONTSIZE_BODY;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .inputJumpTo,
    .MuiTablePagination-select {
        border: 1px solid $COLOR_STEELGREY;
    }

    .MuiTablePagination-input {
        margin-right: 8px;
    }

    .paginationPageNavigation {
        display: flex;
        box-shadow: none;
        margin-left: 10px;
        background-color: inherit;

        .MuiPaginationItem-root {
            border: 1px solid $COLOR_STEELGREY;

            &.Mui-selected {
                color: $COLOR_WHITE;
                background-color: $BUTTON_COLOR_PRIMARY;
            }
        }

        .MuiPaginationItem-ellipsis {
            border: none;
        }

        .MuiPagination-ul {
            flex-wrap: nowrap;
        }
    }

    .paginationSelect {
        .MuiTablePagination-select {
            border-radius: 4px;

            &:focus {
                @include fieldFocusedBorderStyle();
            }
        }
    }

    .paginationJumpTo {
        display: flex;
        align-items: center;

        .inputJumpTo {
            text-align: center;
            border-radius: 4px;
            margin-left: 8px;
            width: 48px;
            height: 32px;
            margin-right: 8px;
            font-size: $FONTSIZE_BODY;

            &:focus {
                @include fieldFocusedBorderStyle();
                outline: none;
            }
        }
    }
}

// Table action group menu and Table pagination popup
.MuiModal-root.MuiPopover-root.MuiMenu-root {
    .MuiPaper-root {
        background-color: transparent;
        box-shadow: none;
        @include popupList('.MuiMenu-list', '.MuiMenuItem-root');
    }
}

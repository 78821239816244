@import '../../mixin.scss';

.muiNativeSelect {
    .MuiNativeSelect-select {
        margin: 0;
        padding: 0 20px 0 0;
        background-color: transparent !important;
        @include selectOptionHeight();
        width: 100%;
    }

    &.placeholderSelect {
        .MuiNativeSelect-select {
            &:first-child {
                @include placeholderTxt();
            }
        }
    }
}

@import 'styles/styles.scss';
.inputLabel {
    display: flex;

    .asterisk {
        color: $COLOR_DANGERRED;
        font-size: $FONTSIZE_18;
    }

    .label {
        &.MuiInputLabel-root {
            font-size: $FONTSIZE_16;
            color: $COLOR_BLACK;
            margin-bottom: 5px;
            white-space: normal;
        }
    }
}

@import 'styles/styles.scss';

$EXCLAMATION_MARK_SIZE: 14px;

.formHelperText {
    display: flex;
    align-items: center;
    @include flexColumnGap(5px);

    .errorIcon {
        margin-top: 2px;
        width: $EXCLAMATION_MARK_SIZE;
        min-width: $EXCLAMATION_MARK_SIZE;
        height: $EXCLAMATION_MARK_SIZE;
    }
}

@import 'styles/styles.scss';

.btnGroupWrapper.btnGroupProcessWrapper {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 340px;

    .btn {
        margin-top: 22px;
    }
}

@import './grid.scss';

$FIELD_HORIZONTAL_PADDING: 16px;

@mixin fieldOutline {
    padding: 12px $FIELD_HORIZONTAL_PADDING;
    height: 44px;
    border-radius: 10px;
    background-color: $COLOR_WHITE;
    @include flexColumnGap(5px);
    @include desktopDown {
        height: 40px;
    }
}

@mixin fieldBorder {
    box-sizing: border-box;
    background-color: $COLOR_WHITE;
    border: 1px $FIELD_BORDER solid;
    border-radius: 10px;
    outline: none;

    fieldset {
        display: none;
    }
}

@mixin fieldFocusedBorderStyle {
    border: 1px $FIELD_FOCUS_BORDER solid;
    box-shadow: 0px 0px 6px rgba($COLOR_ORANGE, 0.5);
    backdrop-filter: blur(20px);
    background-color: $COLOR_WHITE;
    outline: none;
}
@mixin fieldBorderFocused {
    &:focus,
    &.Mui-focused {
        @include fieldFocusedBorderStyle();
    }
}
@mixin fieldBorderHover {
    &:hover {
        outline: none;
        border-color: 1px $FIELD_HOVER_BORDER solid;
        background-color: $COLOR_WHITE;
    }
}

@mixin fieldDisabled {
    &.Mui-disabled,
    &:disabled {
        background-color: $FIELD_DISABLED_BACKGROUND_COLOR !important;
        cursor: default;
    }
}

@mixin fieldText {
    font-size: $FONTSIZE_16;

    .MuiOutlinedInput-input,
    .MuiFilledInput-input {
        &::placeholder {
            color: $COLOR_FIELD_PLACEHOLDER;
            opacity: 1;
        }

        &:first-child {
            padding-left: 0;
        }
        &:nth-last-child(2) {
            padding-right: 0;
        }
    }
}

@mixin fieldAdornment {
    .MuiInputAdornment-root {
        justify-content: center;
        margin: 0;
        height: auto;

        &:first-child {
            margin-left: 0;

            p {
                font-size: $FONTSIZE_16;
                color: $THEME_COLOR_SECONDARY;
                line-height: 1;
            }
        }
        &:nth-last-child(2) {
            margin-right: 0;
        }

        button {
            padding: 0;
        }
    }
    // Date range picker field
    > svg:nth-last-child(2) {
        width: 15px;
    }
    // Select field
    > svg:last-child {
        margin-right: calc(#{$FIELD_HORIZONTAL_PADDING} - 11px);
    }
    // Combo box field
    .MuiAutocomplete-endAdornment {
        margin-right: calc(#{$FIELD_HORIZONTAL_PADDING} - 12px);
    }
}

@mixin fieldStyle {
    @include fieldBorder();
    @include fieldBorderHover();
    @include fieldBorderFocused();
    @include fieldText();
    @include fieldAdornment();
    @include fieldOutline();
    @include fieldDisabled();
}

@import 'styles/styles.scss';

.appFormStyleWrapper {
    &.singleLineFormWrapper {
        max-width: 500px;
    }

    &.doubleLinesFormWrapper {
        max-width: 900px;
    }

    margin-left: auto;
    margin-right: auto;

    .appFormWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .appFieldWrapper {
        margin-top: 50px;
        @include desktopDown {
            margin-top: 12px;
        }
    }

    .formTailCheckBoxWrapper {
        margin-top: 24px;
        text-align: center;
        @include desktopDown {
            margin-top: 12px;
        }
    }
    .formTailWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        @include flexRowGap(20px);

        @include desktopDown {
            @include flexRowGap(12px);
        }
    }

    .btnAppForm {
        width: 80%;
        max-width: 375px;
        @include desktopDown {
            width: 100%;
            max-width: unset;
        }
    }
}

@import 'styles/styles.scss';

.comboBoxField {
    .MuiOutlinedInput-root {
        flex-wrap: nowrap !important;

        .MuiAutocomplete-input {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            height: auto;
        }
    }

    .appFieldWrapper {
        &.textField {
            margin-bottom: 0;
        }
    }

    @include mobile {
        .comboBoxChip {
            .MuiChip-deleteIcon {
                display: none;
            }
        }
    }

    .Mui-disabled {
        .comboBoxChip {
            .MuiChip-deleteIcon {
                display: none;
            }
        }
    }
}

.MuiAutocomplete-popper {
    @include popupList('.MuiPaper-root', '.MuiAutocomplete-option');

    .MuiPaper-root {
        .MuiAutocomplete-noOptions {
            color: $COLOR_WHITE;
        }
    }
}

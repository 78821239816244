@import 'styles/styles.scss';

.btn.btnTableFooter {
    cursor: pointer;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    font-size: $FONTSIZE_BODY;

    .labelTxt {
        color: rgba($COLOR_BLACK, 0.65);
        margin-left: 5px;
    }
}

@mixin popupListItem($backgroundColor: 'transparent', $color: $COLOR_WHITE) {
    background-color: $backgroundColor;
    color: $color;
    margin: 4px 10px;
    padding-left: 6px;
    border-radius: 10px;

    @include mobile {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin popupList($popupClass, $popItemClass) {
    #{$popupClass} {
        border-radius: 10px;
        background-color: rgba($COLOR_BLACK, 0.8);
        backdrop-filter: blur(4px);

        #{$popItemClass} {
            @include popupListItem();

            &[aria-selected='true'],
            &.Mui-focused[aria-selected='true'] {
                @include popupListItem(rgba($THEME_COLOR_PRIMARY, 0.3));

                &:hover {
                    @include popupListItem($THEME_COLOR_PRIMARY);
                }
            }
            &:hover,
            &.Mui-focusVisible {
                @include popupListItem($THEME_COLOR_PRIMARY);
            }
        }
    }
}

@import 'styles/styles.scss';

.appSideContainer {
    width: 310px;
    padding: 30px 0;

    @include desktopDown {
        width: 100%;
        padding: 20px 0 0;
    }

    .MuiGrid-container {
        .MuiGrid-item {
            @include desktopDown {
                padding-top: 12px;
            }
        }
    }
}

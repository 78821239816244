@import 'styles/styles.scss';

@mixin textButton {
    &.MuiButton-text {
        border-color: transparent;
    }

    &.MuiButton-textPrimary {
        color: $BUTTON_COLOR_PRIMARY;
    }
}

@mixin containedButton {
    &.MuiButton-contained {
        color: $COLOR_WHITE;
        border: 1px solid;
        padding: 6px 12px;
    }

    &.MuiButton-containedPrimary {
        background: linear-gradient(
            223.81deg,
            $COLOR_ORANGE 3.09%,
            $COLOR_TIGERORANGE 132.97%
        );
        border-color: $BUTTON_COLOR_PRIMARY;
        transition: all 0.5s;

        svg {
            fill: $COLOR_WHITE;
        }

        p {
            font-size: $FONTSIZE_16;
        }

        &:hover {
            box-shadow: 0px 20px 40px rgba($THEME_COLOR_PRIMARY, 0.25);
        }
    }

    &.MuiButton-containedSecondary {
        background: linear-gradient(
            223.81deg,
            $COLOR_INDIGO 3.09%,
            $THEME_COLOR_SECONDARY 132.97%
        );
        transition: all 0.5s;

        svg {
            fill: $COLOR_WHITE;
        }

        p {
            font-size: $FONTSIZE_16;
        }

        &:hover {
            box-shadow: 0px 20px 40px rgba($THEME_COLOR_SECONDARY, 0.25);

            svg {
                fill: $BUTTON_COLOR_SECONDARY;
            }
        }
    }
}

@mixin outlinedButton {
    &.MuiButton-outlinedSecondary {
        color: $THEME_COLOR_SECONDARY;
        border-color: $THEME_COLOR_SECONDARY;
    }
}

@mixin gradientButton {
    &.MuiButton-gradient {
        background-image: url('../../../assets/images/common/icn_button_gradient.svg');
        background-position: center;

        &:hover {
            opacity: 0.8;
            transition: opacity 0.2s linear;
        }

        .MuiTypography-root {
            color: $COLOR_WHITE;
        }

        .MuiTouchRipple-child {
            background-color: $COLOR_WHITE;
        }
    }
}

button.btn,
span.btn {
    position: relative;
    border-radius: 10px;
    font-size: $FONTSIZE_16;
    font-weight: $FONTWEIGHT_MEDIUM;
    box-shadow: none;
    height: 42px;

    @include textButton;
    @include containedButton;
    @include outlinedButton;
    @include gradientButton;

    &:hover {
        box-shadow: none;
    }

    &.Mui-disabled {
        &:disabled {
            opacity: 0.6;
        }
    }

    &.btnExtendIco {
        font-size: $FONTSIZE_12;

        .MuiButton-startIcon {
            margin-right: 6px;
        }

        .MuiButton-endIcon {
            margin-left: 6px;
        }
    }
}

@import 'styles/styles.scss';

.downloadAutochartist {
    .sideContent {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .autochartistImg {
        width: 152px;
        margin-top: 18px;
    }

    .interfaceImg {
        width: 90px;
    }
}

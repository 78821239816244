.numberField {
    // Hide arrow spin button on number-typed input
    input {
        // Firefox
        appearance: textfield;

        // Chrome, Edge and Safari
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

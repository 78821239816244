@import 'styles/styles.scss';

.remark {
    color: $COLOR_TAUPE_GREY;
    display: flex;
    margin-top: 20px;

    .singleRemark {
        margin-left: 10px;
    }
}

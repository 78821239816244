@import 'styles/styles.scss';

.rowCollapsible {
    display: table-row;
    width: 100%;
    background-color: $COLOR_FLORAL_WHITE;

    .tableCell {
        padding: 0;
        height: 0;
    }
}

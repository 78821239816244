@import 'styles/styles.scss';

.appMainContainer {
    width: 980px;
    padding: 30px 0;

    @include desktopDown {
        width: 100%;
        padding: 0;
    }

    &.fullScreen {
        width: 100%;
    }
}

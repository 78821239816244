@import 'styles/styles.scss';

.verifyCodeField {
    .inputFormControl {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include mobile {
            flex-direction: column;
        }

        &.alignWithButton {
            flex-direction: row;
        }

        .MuiTextField-root {
            flex: 1;
            @include mobile {
                width: 100%;
            }

            &.alignWithButton {
                width: 75%;
            }
        }

        .captchaWrapper {
            display: flex;
            align-self: center;
            align-items: center;
            margin-left: 12px;
            @include mobile {
                margin-top: 12px;
            }

            &.alignWithButton {
                margin-top: 0;
            }

            .captchaImg {
                width: 120px;
                margin-right: 12px;

                img {
                    width: 100%;
                }
            }
        }

        .formHelperText {
            width: 100%;
        }
    }
}

@import 'styles/styles.scss';

.appStatusContentWrapper {
    justify-content: center;
    padding-bottom: 20px;

    .mainContentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
    }

    .statusImgWrapper {
        width: 113px;
        height: 113px;
        @include desktopDown {
            width: 75px;
            height: 75px;
        }
    }
    .titleTxt {
        text-align: center;
        font-size: $FONTSIZE_24;
        font-weight: $FONTWEIGHT_BOLD;
        margin-top: 23px;
        @include desktopDown {
            font-size: $FONTSIZE_18;
        }
    }
    .subtitleTxt {
        text-align: center;
        margin-top: 23px;
    }
    .reminderWrapper {
        font-size: 16px;
        color: $COLOR_TAUPE_GREY;
        margin-top: 23px;
        text-align: center;
    }
    .reminderTxt {
        line-height: 24px;
    }
    .buttonListWrapper {
        margin: 30px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        @include flexRowGap(20px);
        @include mobile {
            @include flexRowGap(12px);
        }
    }
}

@import 'styles/styles.scss';

.pageLayoutSideMain {
    display: flex;

    // @include desktopDown {
    //     margin: 0 15px;
    // }

    .pageLayoutSideMainInner {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include desktopDown {
            flex-direction: column;
        }
    }
}

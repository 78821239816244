@import 'styles/styles.scss';

.downloadMobileApp {
    .sideContent {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    .appImg {
        width: 120px;
        cursor: pointer;
    }
}


@import 'styles/styles.scss';
@import './mixin.scss';

.selectDropdownField {
    .MuiInputBase-root {
        margin-top: 0;
        width: 100%;
        @include fieldStyle();

        &:after,
        &:before {
            display: none;
        }
    }

    .placeholderTxt {
        @include placeholderTxt();
    }

    &.selectPopupField {
        .MuiInputBase-input {
            color: $COLOR_BLACK;
            -webkit-text-fill-color: $COLOR_BLACK;
        }

        .MuiInputBase-root.Mui-disabled {
            background-color: $COLOR_WHITE;
            cursor: pointer;
        }

        .MuiSvgIcon-root {
            &.MuiNativeSelect-icon {
                &.Mui-disabled {
                    color: $SELECT_DROP_DOWN_FIELD_ARROW_ENABLED;
                }
            }
        }
    }
}

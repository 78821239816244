@import 'styles/styles.scss';

.onlineCsBtn {
    display: flex;
    align-items: flex-end;

    &.Mui-disabled:disabled {
        background-color: transparent !important;
        color: $THEME_COLOR_PRIMARY !important;
    }

    @include desktopDown {
        min-width: auto;
    }

    &.black {
        span {
            color: $COLOR_DARKGREY;
        }
    }

    span {
        color: $COLOR_WHITE;
    }

    .onlineCsIco {
        margin-right: 8px;
    }

    .remind {
        margin-left: 5px;
        padding: 2px 4px;

        background-color: $THEME_COLOR_PRIMARY;
        border-radius: 5px;
        font-size: $FONTSIZE_10;
    }
}

@import 'styles/styles.scss';

.languageSwitch {
    position: relative;

    @include desktopDown {
        margin-right: 10px;
        order: 1;
    }

    .MuiButton-root {
        padding-right: 0;
    }

    .flagIco {
        width: 24px;
        border-radius: 4px;
    }

    .langName {
        margin-left: 8px;
        margin-right: 12px;
        color: $COLOR_WHITE;

        @include desktopDown {
            margin-left: 4px;
            margin-right: 0px;
        }

        &.black {
            color: $COLOR_DARKGREY;
        }
    }

    .arrowDownIco {
        width: 20px;
        height: 20px;
        fill: $COLOR_WHITE;

        &.black {
            fill: $COLOR_DARKGREY;
        }
    }
}

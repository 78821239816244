@import 'styles/styles.scss';

.mobileField {
    .mobileFieldWrapper {
        display: flex;
        .phoneCode {
            width: 30%;
            .MuiInputBase-root {
                @include fieldStyle();
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .mobileNumber {
            flex-grow: 1;

            .MuiInputBase-root {
                @include fieldStyle();
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}

@import 'styles/styles.scss';

.btnArrowNavigator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $COLOR_WHITE;
    cursor: pointer;
    padding: 12px 10px 10px 5px;
    border-radius: 8px;

    &:hover {
        background-color: $COLOR_SILVER_PHILIPPINE;
    }

    .infoWrapper {
        display: flex;
        align-items: center;

        .labelWrapper {
            font-size: $FONTSIZE_18;
            margin-left: 20px;
        }
    }
}

@import 'styles/styles.scss';

.basicRegInfo {
    .MuiPaper-root {
        @include basicInfoBg();
    }

    .basicInfoContent {
        display: flex;
        flex-direction: column;
        margin-top: 35px;
    }

    .profilePic {
        width: 120px;
        margin: 0 auto;
    }

    .greeting {
        text-align: center;
        h4 {
            font-size: $FONTSIZE_18;
            font-weight: $FONTWEIGHT_BOLD;
            margin: 15px 0 5px;
        }
    }

    .quickBtnWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .quickBtn {
            width: 230px;
            margin-top: 20px;
        }
    }

    .currentTradeAc {
        text-align: center;
    }

    .detailList {
        margin-top: 22px;

        .detailItm {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        h6 {
            font-weight: $FONTWEIGHT_MEDIUM;
            color: $COLOR_TAUPE_GREY;
        }

        h4 {
            font-size: $FONTSIZE_18;
            font-weight: $FONTWEIGHT_MEDIUM;
            word-break: break-all;
        }
    }
}

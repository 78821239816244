@import 'styles/styles.scss';

.passwordFieldStrengthRemind {
    @include desktopDown {
        margin: 20px 0;
        padding: 20px;
        background-color: $COLOR_WHITE;
        box-shadow: 0 0 4px rgba($COLOR_BLACK, 0.17);
    }

    .strengthBarWrapper {
        margin-top: 6px;
        height: 5px;
        border-radius: 100px;
        width: 100%;
        background-color: $COLOR_STEELGREY;
        position: relative;

        .strengthBar {
            position: absolute;
            height: 5px;
            border-radius: 100px;
        }
    }

    .tooltipHints {
        margin-top: 16px;
    }
}

/*=============================================
=                   Natural Colors            =
=============================================*/
$COLOR_ORANGE: #f39200;
$COLOR_DERBY: #f4e5cc;
$COLOR_MY_SIN_ORANGE: #fbb244;
$COLOR_TIGERORANGE: #fe6c04;
$COLOR_BRIGHT_ORANGE: #ffa412;
$COLOR_ALMOND: #efe2cc;

$COLOR_BLUE_DENIM: #030934;
$COLOR_INDIGO: #091462;
$COLOR_MIDNIGHTBLUE: #1a1f46;
$COLOR_LIGHTBLUE: #367bf5;
$COLOR_ALICE_BLUE: #f0f3f6;
$COLOR_HAWKES_BLUE: #d5d9ee;

$COLOR_MARZIPAN: #eec384;

$COLOR_PERIWINKLE: #cfd2e2;

$COLOR_RED: #ff0000;
$COLOR_REWARDRED: #da291c;
$COLOR_CANDYAPPLE: #f03737;
$COLOR_DANGERRED: #e00000;

$COLOR_GREEN: #32d74b;
$COLOR_GRASSGREEN: #00873c;
$COLOR_LIGHTGREEN: #439a86;

$COLOR_WHITE: #ffffff;
$COLOR_PEARL: #f5f6f9;
$COLOR_LOTION_GREY: #fffcf7;
$COLOR_PALE_GREY: #f0f3f6;
$COLOR_FLORAL_WHITE: #fffbf4;
$COLOR_ANTI_FLASH_WHITE: #f2f2f2;
$COLOR_BRIGHT_GREY: #eeeeee;
$COLOR_LIGHTGREY: #f4f6fc;
$COLOR_CHINESE_SILVER: #cacaca;
$COLOR_STEELGREY: #e9e9e9;
$COLOR_SMOKEGREY: #c4c4c4;
$COLOR_SILVER_PHILIPPINE: #b6b6b0;
$COLOR_DIRTY_SNOW: #cdced6;
$COLOR_SILVER_GREY: #9da6ba;
$COLOR_TAUPE_GREY: #878789;
$COLOR_GREY: #868686;

$COLOR_ASH_GREY: #666666;

$COLOR_GREY_JUDAH_SILK: #47373a;
$COLOR_ECLIPSE: #3e3e3e;
$COLOR_DARKGREY: #222222;
$COLOR_MIDNIGHT: #1a1c2d;
$COLOR_BLACK: #000000;

/*=============================================
=              Theme Colors                   =
=============================================*/
$THEME_COLOR_PRIMARY: $COLOR_ORANGE;
$THEME_COLOR_SECONDARY: $COLOR_BLUE_DENIM;

$BUTTON_COLOR_PRIMARY: $THEME_COLOR_PRIMARY;
$BUTTON_COLOR_SECONDARY: $THEME_COLOR_SECONDARY;

$FIELD_BORDER: $COLOR_SMOKEGREY;
$FIELD_HOVER_BORDER: $COLOR_DARKGREY;
$FIELD_FOCUS_BORDER: $THEME_COLOR_PRIMARY;
$COLOR_FIELD_PLACEHOLDER: $COLOR_SMOKEGREY;
$FIELD_DISABLED_BACKGROUND_COLOR: $COLOR_STEELGREY;

$SELECT_DROP_DOWN_FIELD_ARROW_ENABLED: rgba(0, 0, 0, 0.54);
$SELECT_DROP_DOWN_FIELD_ARROW_DISABLED: rgba(0, 0, 0, 0.26);

/*=============================================
=                  Font Families              =
=============================================*/
$FONT_PRIMARY: 'Arial', 'NotoSansSC';

/*=============================================
=                  Font Sizes                 =
=============================================*/
$FONTSIZE_10: 10px;
$FONTSIZE_12: 12px;
$FONTSIZE_BODY: 14px;
$FONTSIZE_16: 16px;
$FONTSIZE_18: 18px;
$FONTSIZE_24: 24px;
$FONTSIZE_22: 22px;
$FONTSIZE_20: 20px;
$FONTSIZE_28: 28px;
$FONTSIZE_32: 32px;
$FONTSIZE_36: 36px;

/*=============================================
=                  Font Weights               =
=============================================*/
$FONTWEIGHT_NORMAL: 400;
$FONTWEIGHT_MEDIUM: 500;
$FONTWEIGHT_SEMIBOLD: 600;
$FONTWEIGHT_BOLD: 700;
$FONTWEIGHT_EXTRABOLD: 800;
$FONTWEIGHT_BLACK: 900;

/*=============================================
=                   Spacing                   =
=============================================*/
$SPACING_LAYOUT_LARGE: 30px;
$SPACING_LAYOUT_MEDIUM: 20px;
$SPACING_LAYOUT_SMALL: 10px;

/*=============================================
=                  Breakpoints                =
=============================================*/

$mobile: 0;
$tablet: 768;
$laptop: 960;
$desktop: 1320;

$MOBILE_BREAKPOINT: #{$mobile}px;
$TABLET_BREAKPOINT: #{$tablet}px;
$LAPTOP_BREAKPOINT: #{$laptop}px;
$DESKTOP_BREAKPOINT: #{$desktop}px;

/*=============================================
=                  z-index                 =
=============================================*/
// cross components, relative to mui's components z-index
$Z_INDEX_HEADER: 1101;
$Z_INDEX_HEADER_MOBILE: 1100;
$Z_INDEX_HEADER_MENU: 1105;
$Z_INDEX_WIDGET_LIVE_CHAT: 1102;
// within component
$Z_INDEX_WITHIN_COMPONENT_999: 999;
$Z_INDEX_WITHIN_COMPONENT_99: 99;
$Z_INDEX_WITHIN_COMPONENT_9: 9;

$Z_INDEX_WITHIN_COMPONENT_1: 1;
$Z_INDEX_WITHIN_COMPONENT_0: 0;
$Z_INDEX_WITHIN_COMPONENT_-1: -1;

@import 'styles/styles.scss';

.selectItemClientGroupWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .clientGroupInfoBoxWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        border: 1px solid $COLOR_PEARL;
        border-radius: 4px;
        padding: 8px 16px;
        width: 500px;

        @include desktopDown {
            padding: 8px;
        }

        .clientGroupInfoWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 48px;

            .appFieldWrapper {
                flex: 1;
            }

            .clientGroupField {
                margin-left: 24px;

                @include desktopDown {
                    margin-left: 12px;
                }

                .MuiInputBase-root,
                .MuiInputBase-root:focus {
                    border: none;
                    box-shadow: none;

                    &.Mui-disabled {
                        background-color: $COLOR_WHITE !important;
                    }
                }
                .MuiInputBase-input {
                    color: $COLOR_BLACK;
                    -webkit-text-fill-color: $COLOR_BLACK;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }
        }

        .btnComplete {
            color: $COLOR_GREEN;

            @include desktopDown {
                font-size: $FONTSIZE_12;
            }
        }
    }

    .selectSectionWrapper {
        .btnDelete {
            color: $COLOR_RED;

            @include desktopDown {
                font-size: $FONTSIZE_12;
                padding-right: 0;
                min-width: auto;
            }
        }
    }

    &.selectedItem {
        .selectHolder {
            &:hover {
                cursor: auto;
            }
        }
    }

    &.extraItem {
        .selectHolder {
            &:hover {
                cursor: not-allowed;
            }
        }

        &.selectedItem {
            .selectHolder {
                &:hover {
                    cursor: auto;
                }
            }
        }
    }

    &.modifyingItem {
        .indicatorColorCircle {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .selectHolder {
        width: 24px;
        height: 24px;
        border: 1px solid $COLOR_STEELGREY;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 16px;

        &:hover {
            cursor: pointer;
        }
    }

    .colorSetWrapper {
        display: flex;
        .indicatorColorCircle {
            margin-right: 16px;

            @include desktopDown {
                margin-right: 10px;
            }

            cursor: pointer;
        }
    }

    .nonUserGroupTxt {
        color: $COLOR_SMOKEGREY;
    }
}

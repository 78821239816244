@import 'styles/styles.scss';

$PICKER_POPOVER_BACKGROUND_COLOR: rgba($COLOR_BLACK, 0.8);
$DISABLE_DAY_COLOR: $COLOR_ASH_GREY;

@mixin setSelectedBorderRadius($borderRadius) {
    .rdrStartEdge {
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

    .rdrEndEdge {
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .rdrSelected {
        border-radius: $borderRadius;
    }

    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
}

@mixin setPreviewBorderRadius($borderRadius) {
    .rdrDayStartOfMonth .rdrDayInPreview,
    .rdrDayStartOfMonth .rdrDayEndPreview,
    .rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfWeek .rdrDayEndPreview {
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

    .rdrDayEndOfMonth .rdrDayInPreview,
    .rdrDayEndOfMonth .rdrDayStartPreview,
    .rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfWeek .rdrDayStartPreview {
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .rdrDayStartPreview {
        border-top-left-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }
    .rdrDayEndPreview {
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }
}

.dateRangePickerField {
    .fieldsWrapper {
        display: flex;

        .hyphen {
            align-self: center;
            margin: 0 8px 0 8px;
        }

        .startDate,
        .endDate {
            flex: 1;

            &.focused {
                .MuiInputBase-root {
                    @include fieldFocusedBorderStyle();
                }
            }

            .MuiInputBase-root {
                @include fieldStyle();

                &:focus,
                &.Mui-focused {
                    @include fieldBorder();
                    box-shadow: none;
                }
            }
        }
    }
}

.dateRangePopover {
    &.disabledCalendar {
        .rdrCalendarWrapper {
            &.rdrDateRangeWrapper {
                display: none;
            }
        }
    }

    .MuiPaper-root {
        &.MuiPopover-paper {
            border-radius: 10px;
            margin: 8px 0 8px 0;
            backdrop-filter: blur(4px);
            background-color: $PICKER_POPOVER_BACKGROUND_COLOR;
            padding: 16px;
            @include tabletDown {
                max-width: 350px;
            }

            .rdrDateRangePickerWrapper {
                display: flex;
                flex-direction: column;

                .rdrDefinedRangesWrapper {
                    width: 100%;
                    padding: 0 0.833em;
                    background-color: transparent;
                    border: 0px;

                    .rdrStaticRanges {
                        flex-direction: row;
                        @include flexColumnGap(8px);
                        overflow: auto;
                        padding: 4px 0 10px 0;

                        .rdrStaticRange {
                            display: inline-flex;
                            outline: 0;
                            padding: 0;
                            font-size: 0.8125rem;
                            transition: background-color 300ms
                                    cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                                box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)
                                    0ms;
                            align-items: center;
                            white-space: nowrap;
                            border-radius: 10px;
                            vertical-align: middle;
                            justify-content: center;
                            text-decoration: none;
                            cursor: pointer;
                            user-select: none;
                            -webkit-tap-highlight-color: transparent;
                            font-size: $FONTSIZE_BODY;
                            height: 30px;
                            border: 1px solid $COLOR_TAUPE_GREY;
                            color: $COLOR_WHITE;
                            background-color: $THEME_COLOR_SECONDARY;

                            .rdrStaticRangeLabel {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: $FONTSIZE_BODY;
                                font-weight: $FONTWEIGHT_SEMIBOLD;
                                padding: 0 20px;
                            }

                            &:hover,
                            &:focus {
                                border-color: $THEME_COLOR_PRIMARY;

                                .rdrStaticRangeLabel {
                                    background-color: transparent;
                                }
                            }

                            &.rdrStaticRangeSelected {
                                color: $COLOR_WHITE !important;
                                background-color: $THEME_COLOR_PRIMARY;
                                border: 1px solid $THEME_COLOR_PRIMARY;
                            }
                        }
                    }

                    .rdrInputRanges {
                        padding: 0;
                    }
                }

                .rdrCalendarWrapper {
                    background-color: transparent;

                    .rdrMonthAndYearWrapper {
                        padding: 0 0 10px 0;

                        button {
                            background-color: $THEME_COLOR_SECONDARY;
                            border: 1px solid $COLOR_TAUPE_GREY;
                            i {
                                filter: invert(1);
                            }
                        }
                        .rdrNextButton i {
                            margin-left: 6px;
                        }
                        .rdrMonthAndYearPickers select {
                            color: $COLOR_WHITE;
                            background: url('/assets/images/common/icn_arrow_down_white.svg')
                                no-repeat right 8px center;
                            background-color: $COLOR_MIDNIGHT;
                            border: 1px solid $COLOR_TAUPE_GREY;
                            border-radius: 10px;
                        }
                    }

                    .rdrMonths {
                        @include flexColumnGap(16px);
                        padding: 0 4px;

                        .rdrMonth {
                            @include setSelectedBorderRadius(8px);
                            @include setPreviewBorderRadius(8px);
                            padding: 0;

                            .rdrMonthName {
                                color: $COLOR_SMOKEGREY;
                            }
                            .rdrWeekDays .rdrWeekDay {
                                color: $COLOR_SMOKEGREY;
                            }
                            .rdrDayPassive .rdrDayNumber span {
                                color: $DISABLE_DAY_COLOR;
                            }
                            .rdrDayNumber span {
                                color: $COLOR_WHITE;
                            }
                            .rdrDayToday {
                                .rdrDayNumber {
                                    span:after {
                                        background-color: $THEME_COLOR_PRIMARY;
                                    }
                                }
                            }
                            .rdrDayDisabled {
                                background-color: transparent;
                                span.rdrDayNumber span {
                                    color: $DISABLE_DAY_COLOR;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

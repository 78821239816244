@import 'styles/styles.scss';

.radioButtonField {
    .MuiFormLabel-root {
        color: $COLOR_BLACK;
    }

    .MuiFormGroup-root {
        .MuiFormControlLabel-root {
            align-items: flex-end;
            margin-bottom: 0;

            .MuiRadio-root {
                padding-bottom: 0;
            }
        }
    }
}

@import 'styles/styles.scss';

.card.cardTabControl.cardTableCommissionTable {
    margin-top: 32px;
    margin-bottom: 0;

    .cardTabContent.tabContentWrapper {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}

@import 'styles/styles.scss';

.fieldActionSearch {
    display: flex;
    align-items: center;
    max-width: 500px;
    width: 100%;

    .fieldWrapper {
        width: 100%;
        max-width: 500px;

        .MuiFormControl-root {
            width: 100%;
        }

        .inputField {
            .MuiInputBase-root {
                height: 50px;
                border-radius: 5px 0 0 5px;
            }
        }

        .formHelperText {
            display: none;
        }
    }

    .btnWrapper {
        .btn {
            height: 50px;
            border-radius: 0 5px 5px 0;
            border: none;
        }
    }
}

@import 'styles/styles.scss';

nav {
    position: relative;
    display: flex;
    align-items: center;
    height: 90px;
    background-color: rgba($THEME_COLOR_SECONDARY, 0.9);

    @include desktopDown {
        height: 72px;
    }

    .navWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $DESKTOP_BREAKPOINT;
        width: 100%;
        margin: 0 auto;

        @include desktopDown {
            align-items: center;
            margin: 0 12px;
        }
    }

    .logo {
        width: 230px;

        @include desktopDown {
            width: 140px;
        }
    }

    .menuBtn {
        position: relative;
        padding: 10px 0 10px 10px;

        span {
            position: relative;
            display: block;
            width: 24px;
            height: 2px;
            margin: 4px 0;
            background-color: $COLOR_WHITE;
            transition: all 0.3s;
        }

        &.menuOpen {
            .line1 {
                top: 3px;
                transform: rotate(45deg);
            }

            .line2 {
                display: none;
            }

            .line3 {
                top: -3px;
                transform: rotate(-45deg);
            }
        }
    }

    .navItmList {
        display: flex;
        align-items: center;
    }

    .navItm {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            .arrowDown {
                transform: rotate(180deg) scaleX(-1);
            }

            .hoverMenu {
                visibility: visible;
                opacity: 1;
                max-height: 400px;
                transition: visibility 0s, opacity 0.3s;
            }
        }
    }
}

.menuDrawer {
    .MuiPaper-root {
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($COLOR_MIDNIGHTBLUE, 0.95);
        backdrop-filter: blur(5px);
    }

    .logo {
        width: 165px;
        margin: 30px 20px;
    }

    .navItmList {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
    }

    .divider {
        margin: 0 20px 20px;
        border-color: rgba($COLOR_WHITE, 0.2);
    }

    .navItm {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;

        &:active,
        &:hover {
            .arrowDown {
                transform: rotate(180deg) scaleX(-1);
            }

            .hoverMenu {
                &.MuiList-root {
                    width: 100%;
                    max-height: 400px;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .onlineCsBtn {
            justify-content: flex-start;
            width: 100%;
            padding: 6px 0;

            .onlineCsIco {
                fill: $COLOR_WHITE;
                margin-right: 8px;
                margin-left: 2px;
            }
        }

        .languageSwitch {
            width: 100%;

            &:hover {
                .arrowDownIco {
                    transform: rotate(180deg) scaleX(-1);
                }

                .langMenu {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .MuiButton-root {
                justify-content: flex-start;
                width: 100%;
                margin-left: 2px;
                padding: 6px 0;
            }

            .arrowDownIco {
                position: absolute;
                right: 0;
            }
        }

        .langMenu {
            &.MuiList-root {
                max-height: 0;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s;

                li {
                    color: $COLOR_WHITE;
                    padding: 8px 2px;

                    &.selected {
                        font-weight: $FONTWEIGHT_BOLD;
                        color: $COLOR_ORANGE;
                    }
                }
            }
        }
    }
}

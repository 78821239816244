@import 'styles/styles.scss';

.withdrawRecord,
.depositRecord {
    text-align: center;

    .fundflowItm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid $COLOR_LIGHTGREY;

        &:last-child {
            border-bottom: none;
        }
    }
    .merchantIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        padding: 2px;
        margin-right: 12px;
        border: 1px solid rgba($THEME_COLOR_SECONDARY, 0.5);
        border-radius: 50%;

        &.orangeBg {
            background-color: $COLOR_ORANGE;

            svg {
                width: 20px;
                height: 20px;
                fill: $COLOR_WHITE;
            }
        }

        &.redBg {
            background-color: $COLOR_REWARDRED;

            svg {
                width: 20px;
                height: 20px;
                fill: $COLOR_WHITE;
            }
        }
    }

    .leftSide {
        display: flex;
        align-items: center;
    }

    .method {
        display: flex;
        align-items: center;

        p,
        .signItm {
            margin-right: 8px;
        }
    }

    .sign {
        display: flex;
    }

    .date {
        text-align: left;
    }

    .amount {
        font-weight: $FONTWEIGHT_BOLD;
    }

    .checkMore {
        font-size: 17px;
        font-weight: $FONTWEIGHT_MEDIUM;
        width: 200px;
        height: 50px;
        border-radius: 50px;
    }
}

@import 'styles/styles.scss';

.headerLineTopWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .headerLineTop {
        &.MuiDivider-root {
            background-color: $THEME_COLOR_PRIMARY;
            border-color: $THEME_COLOR_PRIMARY;
            border-width: 0;
            width: 50px;
            height: 3px;
            border-radius: 2px;
        }
    }

    .titleTxt {
        color: $THEME_COLOR_SECONDARY;
        font-size: $FONTSIZE_24;
        font-weight: $FONTWEIGHT_BOLD;
        margin-top: 20px;
        text-align: center;

        @include mobile {
            font-size: $FONTSIZE_18;
        }
    }
}

.clientGroupSelectWrapper {
    .btnGroupWrapper {
        display: flex;
        justify-content: flex-end;
    }
    .btnConfirm {
        margin-top: 40px;
        align-self: flex-end;
        width: 142px;
    }
}

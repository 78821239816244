@import 'styles/styles.scss';

$POPUP_BORDER_RADIUS: 10px;
$BUTTON_BORDER_RADIUS: 8px;

.datepickerField {
    &.pointerCursor {
        .MuiInputBase-root,
        .MuiInputBase-input {
            cursor: pointer;
        }
    }

    .arrowDownIcon {
        width: 32px !important;
        height: 20px;
        margin-right: -5px !important;
    }
}

div[role='dialog'] > .MuiPaper-root {
    margin: 8px 0 8px 0;
    border-radius: $POPUP_BORDER_RADIUS !important;
    background-color: rgba($COLOR_BLACK, 0.8);
    backdrop-filter: blur(4px);

    .MuiCalendarPicker-root {
        border-radius: $POPUP_BORDER_RADIUS;
        color: $COLOR_WHITE;

        .MuiTypography-root {
            color: $COLOR_WHITE;
        }
        .MuiButtonBase-root {
            border-radius: $BUTTON_BORDER_RADIUS;

            &:not(.Mui-selected) {
                color: $COLOR_WHITE;
                background-color: transparent;
            }
            &.Mui-selected {
                color: $COLOR_WHITE;
                background-color: $THEME_COLOR_PRIMARY;
            }
            &:hover {
                background-color: rgba($THEME_COLOR_PRIMARY, 0.4);
            }
            &.MuiPickersDay-today {
                color: $THEME_COLOR_PRIMARY;
                border-color: $THEME_COLOR_PRIMARY;

                &.Mui-selected {
                    color: $COLOR_WHITE;
                }
            }
        }

        .MuiMonthPicker-root {
            .PrivatePickersSlideTransition-root {
                min-height: 245px;
            }

            .PrivatePickersYear-yearButton {
                border-radius: $BUTTON_BORDER_RADIUS;

                &:hover {
                    background-color: rgba($THEME_COLOR_PRIMARY, 0.4);
                }
                &.Mui-selected {
                    color: $COLOR_WHITE;
                    background-color: $THEME_COLOR_PRIMARY;
                }
            }

            .PrivatePickersMonth-root {
                &:disabled {
                    opacity: 0.5;
                }
            }
        }
    }
}

@import 'styles/styles.scss';

.sectionDashLinWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px dashed $THEME_COLOR_PRIMARY;
    border-radius: 4px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: $COLOR_LOTION_GREY;
}

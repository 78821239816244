// Workaround for flex row-gap on unsupported browser
@mixin flexRowGap($size) {
    > *:not(:last-child) {
        margin-bottom: $size;
    }
}

// Workaround for flex column-gap on unsupported browser
@mixin flexColumnGap($size) {
    > *:not(:last-child) {
        margin-right: $size;
    }
}

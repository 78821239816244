@font-face {
    font-family: 'NotoSansSC';
    font-weight: 400;
    font-display: swap;
    src: local('NotoSansSC'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Regular.woff2')
            format('woff2'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Regular.woff') format('woff');
}

@font-face {
    font-family: 'NotoSansSC';
    font-weight: 500;
    font-display: swap;
    src: local('NotoSansSC'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Medium.woff2')
            format('woff2'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Medium.woff') format('woff');
}

@font-face {
    font-family: 'NotoSansSC';
    font-weight: 700;
    font-display: swap;
    src: local('NotoSansSC'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Bold.woff2') format('woff2'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Bold.woff') format('woff');
}

@font-face {
    font-family: 'NotoSansSC';
    font-weight: 900;
    font-display: swap;
    src: local('NotoSansSC'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Black.woff2') format('woff2'),
        url('../assets/fonts/NotoSansSC/NotoSansSC-Black.woff') format('woff');
}

@import 'styles/styles.scss';

.noContentBoard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .image {
        width: 145px;
        height: 130px;
        margin-bottom: $SPACING_LAYOUT_LARGE;

        @include desktopDown {
            width: 75px;
            height: 65px;
        }
    }
    .label {
        font-size: $FONTSIZE_28;

        @include desktopDown {
            font-size: $FONTSIZE_18;
        }
    }
}

@import 'styles/styles.scss';

.passwordField {
    .appFieldWrapper {
        &.textField {
            margin-top: 0;
        }
    }
}

#passwordFieldToolTip.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        width: 270px;
        background-color: $COLOR_WHITE;
        color: $COLOR_BLACK;
        border-radius: 2px;
        box-shadow: 0 0 4px rgba($COLOR_BLACK, 0.17);
        font-size: $FONTSIZE_BODY;

        border: 1px $COLOR_STEELGREY solid;
        border-right-width: 0;
        border-top-width: 0;
        transform: rotate(45deg);
        z-index: $Z_INDEX_WITHIN_COMPONENT_-1;
        padding: 16px;
    }

    .tooltipArrow {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 23%;
        left: -9px;
        transform: translateX(-50%);
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            background: white;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
            top: 50%;
            left: 100%;
            box-shadow: 0 0 4px rgba($COLOR_BLACK, 0.17);
        }
    }
}

@import 'styles/styles.scss';

.textAreaField {
    .redOutlined {
        border-color: $COLOR_REWARDRED;
    }
    textarea {
        font-family: $FONT_PRIMARY;
        @include fieldStyle();

        &::placeholder {
            color: $COLOR_SILVER_PHILIPPINE;
            font-size: $FONTSIZE_BODY;
            font-weight: $FONTWEIGHT_NORMAL;
        }
    }
}

@import 'styles/styles.scss';
@import './constants.module.scss';

.appDialog {
    .MuiBackdrop-root {
        backdrop-filter: blur(4px);
    }

    .MuiPaper-root {
        max-width: none;
        min-width: 420px;
        border-radius: 10px;
        overflow-x: hidden;

        @include tabletDown {
            min-width: unset;
            width: 100%;
            margin: 0px 12px;
        }

        .dialogHeader {
            display: flex;
            flex-wrap: wrap;
            position: sticky;
            align-items: center;
            justify-content: space-between;
            padding: 24px 20px;
            top: 0;
            background-color: $COLOR_WHITE;
            z-index: $Z_INDEX_WITHIN_COMPONENT_1;

            @include desktopDown {
                padding: 24px 15px;
            }

            &.headerBackground {
                padding: 16px 16px 64px 16px;
                margin: 0px -1px; // remove white space at the corners of the header of app dialog
                background-image: url(../../assets/images/common/img_dialog_background.png);
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-clip: padding-box;

                .dialogTitle {
                    color: $COLOR_WHITE;
                    line-height: 25px;
                }
            }

            .dialogTitle {
                position: relative;
                display: flex;
                align-items: center;
                padding: 0;
                font-size: $HEADER_FONT_SIZE;
                font-weight: $FONTWEIGHT_BOLD;
                line-height: 20px;
                flex: 1;
                transition: 0.3s;

                @include desktopDown {
                    font-size: $FONTSIZE_20;
                    line-height: 15px;
                }

                @include headerBeforeDecoration(
                    $HEADER_LEFT_DECORATION_WIDTH,
                    $HEADER_LEFT_DECORATION_MARGIN_RIGHT
                );
            }

            .dialogBtnCloseWrapper {
                min-width: 32px;
                align-self: flex-start;

                .btnClose {
                    .MuiSvgIcon-root {
                        width: 1.5em;
                        height: 1.5em;
                    }
                }
            }

            .dialogSubTitle {
                margin-top: 10px;
                margin-left: calc(
                    #{$HEADER_LEFT_DECORATION_WIDTH} + #{$HEADER_LEFT_DECORATION_MARGIN_RIGHT}
                );
                width: 100%;
                z-index: $Z_INDEX_WITHIN_COMPONENT_1;
                color: $COLOR_WHITE;
                font-size: $FONTSIZE_24;
            }
        }

        .dialogContent {
            height: 100%;
            padding: 5px 20px 30px;

            @include desktopDown {
                padding: 5px 15px 30px;
            }
        }
    }

    &.qrCodeDialog {
        .MuiPaper-root {
            min-width: unset;
            width: 380px;

            .dialogContent {
                padding: 50px;
            }
        }
    }
}

@import 'styles/styles.scss';

.scrollSelect {
    position: relative;

    @include tabArrowBtn($COLOR_WHITE, $THEME_COLOR_SECONDARY);

    .MuiTabs-indicator {
        display: none;
    }
}

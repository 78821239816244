@import 'styles/styles.scss';

header {
    background-color: $THEME_COLOR_SECONDARY;
    .headerWrapper {
        position: relative;
        max-width: $DESKTOP_BREAKPOINT;
        margin: 0 auto;

        .headerContent {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 50px;

            .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 120px;

                img {
                    width: 100%;
                }
            }

            .headerRight {
                display: flex;
                align-items: center;
            }

            .onlineCsBtn {
                .onlineCsIco {
                    fill: $COLOR_WHITE;
                }
            }

            .divider {
                border-color: $COLOR_WHITE;
                height: 12px;
                margin: 0 18px;
            }

            @include languageSwitch();

            @include langMenu();
        }
    }
}
